import React from "react";
import styled from "styled-components";
import { Typography, Button } from "@mui/material";
import { imageURL } from "../../../Api";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { useNavigate } from "react-router-dom";
import icon from "../../../assets/images/Tia - font.png";
function Cards({ id, image, name, status, data }) {
  console.log(id, image, name, status);
  const { setProfileViewData } = useGlobalContext();
  const navigate = useNavigate();
  const buttonColor = status === 'active' ? 'green' : 'red';
  return (
    <Container>
      <Card>
        <Typography>{id}</Typography>
        {/* <Image src={imageURL(image)} alt="" /> */}
        <Image src={icon} alt="" />
        <Name>{name}</Name>
        <ButtonContainer>
        <Button
  sx={{
    color: buttonColor,
    borderColor: buttonColor,
    '&:hover': {
      borderColor: buttonColor,
      backgroundColor: `${buttonColor}33`, // Optional: Adjust the hover background color
    }
  }}
  variant="outlined"
  size="small"
>
  {status}
</Button>
          <Button
  onClick={() => {
    setProfileViewData(data);
    navigate("/profile");
  }}
  sx={{
    color: "white",
    borderColor: "white",
    '&:hover': {
      borderColor: "white",
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: Adjust the hover background color
    }
  }}
  variant="outlined"
  size="small"
>
            View Profile
          </Button>
        </ButtonContainer>
      </Card>
    </Container>
  );
}

// Container for the card
const Container = styled.div`
  width: 20rem;
  padding: 1em;
  position: relative;
`;

const Card = styled.div`
  background-color: #25272d;
  width: 80%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  transition: transform 0.3s, background-color 0.3s; // Smooth transition for transform and background color
  &:hover {
    background-color: #333; // Darken the background color on hover
    transform: scale(1.05); // Slightly scale up the card on hover
  }
`;

// Styled component for the image with hover effect
const Image = styled.img`
  height: 50%;
  width: 50%;
  border-radius: 100%;
  transition: transform 0.3s; // Smooth transition for transform
  &:hover {
    transform: scale(1.1); // Slightly scale up the image on hover
  }
`;

// Styled component for the name text
const Name = styled.span`
  color: white; // Adjust text color
  transition: color 0.3s; // Smooth transition for color
  &:hover {
    color: #00ff00; // Change text color to green on hover
  }
`;

// Styled component for the button container
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export default Cards;
