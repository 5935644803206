import React, { useEffect, useState } from "react";
import {
  DialogTitle,    // Ensure this is imported
  DialogContent,  // Ensure this is imported
  DialogActions,  // Ensure this is imported
} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress,
  Stack,
  Box,
  ButtonBase
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Carousel } from 'react-responsive-carousel';

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import NavBar from "../miniComponents/subcomponents/NavBar";
import InfoIcon from "@mui/icons-material/Info";
import MiniNavBar from "../miniComponents/subcomponents/MiniNavBar";
import { deleteData, fetchData, imageURL } from "../../Api";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useGlobalContext } from "../../assets/contex/GlobalContext";
import { URL as url} from "../../assets/mocData/config";
import RedirectPage from "../miniComponents/templatePages/RedirectPage";
import icon from "../../assets/images/Tia - font.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StudentProfille = ( 
) => {
  const navigate = useNavigate();
  const {userRole,userToken,profileViewData} = useGlobalContext();
  console.log('this is student data')
 console.log(profileViewData)
  const [open, setOpen] = useState(false);

  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [marketingDatas,setMarketingDatas]=useState([]);
  const [data,setData]=useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const deleteUser = async (id) => {
    let headers = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      await deleteData(`${url}/admin/users/${id}`, headers);
      enqueueSnackbar("User deleted successfully", {
        autoHideDuration: 1000,
        variant: "success",
      });
      navigate(-1); // Navigate back after successful deletion
    } catch (error) {
      enqueueSnackbar("Failed to delete user", {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error("Error deleting user:", error);
    }
  };

  
  const handleClickOpen = () => {
    setDialogOpen(true); // Open the delete confirmation dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog without deleting
  };


  

  const openModal = (images) => {
    setCurrentImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImages([]);
  };

if(profileViewData?.personalInfo?.studentName!==undefined){
  return (
    <ProfileContainer style={{ backgroundColor: "#18171b", color: "#fff" }}>
      <NavBar />
      <MiniNavBar />
      <Container className="scroll" maxWidth="lg" sx={{ py: 5 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ mb: 4, color: "#fff" }}
        >
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent sx={{ textAlign: "center" }}>
              <CardMedia
  component="img"
  // image={
  //   profileViewData.imageACCESSKEY 
  //     ? imageURL(profileViewData.imageACCESSKEY) 
  //     : "path_to_placeholder_image.jpg" // replace with your placeholder image URL
  // }
  image={icon}
  sx={{
    width: 150,
    height: 150,
    borderRadius: "50%",
    mx: "auto",
  }}
/>


                <Text variant="h6" sx={{ mt: 2 }}>
                  <b>STUDENT</b>
              </Text>
                <Text variant="body2" color="textSecondary">
                 {profileViewData.status}
                </Text>
                {/* <Button variant="outlined" sx={{ mt: 2 }}>
                  Message
                </Button> */}
              </CardContent>
            </Card>
            <Card
              sx={{ bgcolor: "var(--navbar-dark-primary)", marginTop: "1rem" }}
            >
              <CardContent>
                <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                  <b>Family Details</b>
                </Text>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Father Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                     {profileViewData?.familyDetails?.fathersName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Mother Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.familyDetails?.mothersName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Father Phone Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.familyDetails?.fathersPhone}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Mother Phone Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.familyDetails?.mothersPhone}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                    <Grid item xs={4}>
                    <Text variant="body2">Father Whatsapp Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.familyDetails?.fathersWhatsAppNumber}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Mother Whatsapp Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.familyDetails?.mothersWhatsAppNumber}
                    </Text>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Student Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.personalInfo?.studentName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Concept Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.courseDetail?.conceptName.conceptName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Course Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.courseDetail?.courseName.courseName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Branch Joined</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {profileViewData?.courseDetail?.branchName.branchName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Personal Information</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Age</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.personalInfo?.age}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Gender</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                         {profileViewData?.personalInfo?.gender}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Blood Group</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.personalInfo?.bloodGroup}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                        <Grid item xs={4}>
                        <Text variant="body2">Class</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.personalInfo?.classCourse}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                        <Grid item xs={4}>
                        <Text variant="body2">School</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.personalInfo?.schoolCollege}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary"></Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card
              sx={{ bgcolor: "var(--navbar-dark-primary)", marginTop: "1rem" }}
            >
             <CardContent>
  <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
    <b>Sibling Details</b>
  </Text>
  {profileViewData?.familyDetails?.sibilings?.map((sibiling, index) => {
    return (
      <Grid container spacing={2} key={index}>  {/* Added container and spacing for layout */}
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Text variant="body2">Name</Text>
        </Grid>
        <Grid item xs={8}>
          <Text variant="body2" color="textSecondary">
            {sibiling.name}
          </Text>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Text variant="body2">Age</Text>
        </Grid>
        <Grid item xs={8}>
          <Text variant="body2" color="textSecondary">
            {sibiling.age}
          </Text>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Text variant="body2">Gender</Text>
        </Grid>
        <Grid item xs={8}>
          <Text variant="body2" color="textSecondary">
            {sibiling.gender}
          </Text>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Text variant="body2">Class</Text>
        </Grid>
        <Grid item xs={8}>
          <Text variant="body2" color="textSecondary">
            {sibiling.classCourse}
          </Text>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Text variant="body2">School</Text>
        </Grid>
        <Grid item xs={8}>
          <Text variant="body2" color="textSecondary">
            {sibiling.schoolCollege}
          </Text>
        </Grid>
      </Grid>
    );
  })}
</CardContent>

            </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Address Details</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Door/Flat no</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.address?.flatNo}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Street Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                           {profileViewData?.address?.street}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Landmark</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.address?.landmark}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">District</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.address?.district}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">State</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.address?.state}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Country</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.address?.country}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Pin Code</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {profileViewData?.address?.pinCode}
                        </Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
  <CardContent>
    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
      <b>Student Feedback</b>
    </Text>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Text variant="body2">Feedback status</Text>
      </Grid>
      <Grid item xs={8}>
        <Text variant="body2" color="textSecondary">
          {profileViewData?.feedBackquestion?.feedBacks || "No feedback available"}
        </Text>
      </Grid>
      {profileViewData?.feedBackquestion?.feedBacks !== "notyet" && (
  <>
    <Grid item xs={4}>
      <Text variant="body2">Question 1</Text>
    </Grid>
    <Grid item xs={8}>
  <Text variant="body2" color="textSecondary">
    {profileViewData?.feedBackquestion?.feedback1 
      ? '★'.repeat(profileViewData.feedBackquestion.feedback1) // Repeat the star symbol based on feedback value
      : "No feedback available"}
  </Text>
</Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={4}>
      <Text variant="body2">Question 2</Text>
    </Grid>
    <Grid item xs={8}>
  <Text variant="body2" color="textSecondary">
    {profileViewData?.feedBackquestion?.feedback2 
      ? '★'.repeat(profileViewData.feedBackquestion.feedback2) // Repeat the star symbol based on feedback value
      : "No feedback available"}
  </Text>
</Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={4}>
      <Text variant="body2">Question 3</Text>
    </Grid>
    <Grid item xs={8}>
  <Text variant="body2" color="textSecondary">
    {profileViewData?.feedBackquestion?.feedback3 
      ? '★'.repeat(profileViewData.feedBackquestion.feedback3) // Repeat the star symbol based on feedback value
      : "No feedback available"}
  </Text>
</Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={4}>
      <Text variant="body2">Question 4</Text>
    </Grid>
    <Grid item xs={8}>
  <Text variant="body2" color="textSecondary">
    {profileViewData?.feedBackquestion?.feedback4 
      ? '★'.repeat(profileViewData.feedBackquestion.feedback4) // Repeat the star symbol based on feedback value
      : "No feedback available"}
  </Text>
</Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={4}>
      <Text variant="body2">Question 5</Text>
    </Grid>
    <Grid item xs={8}>
  <Text variant="body2" color="textSecondary">
    {profileViewData?.feedBackquestion?.feedback5 
      ? '★'.repeat(profileViewData.feedBackquestion.feedback5) // Repeat the star symbol based on feedback value
      : "No feedback available"}
  </Text>
</Grid>

  </>
)}
               
                    </Grid>
                  </CardContent>
                </Card>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              TABLES
            </Typography>
          </Toolbar>
        </AppBar>
        <ImageList>
          <CrmAdminFranchiseViewContainer>
            <div id="viewvip-table-container">
              <div id="table-container" style={{ height: "60vh" }}>
                <h5 className="text-white">MARKETING UPDATES</h5>
                <div className="scroll">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="t-head rounded-start-3" scope="col">
                          S.no
                        </th>
                        <th className="t-head" scope="col">
                          DATE
                        </th>
                        <th className="t-head" scope="col">
                          TIME
                        </th>
                        <th className="t-head" scope="col">
                          IMAGES
                        </th>
                        <th className="t-head" scope="col">
                          UPLOAD MEDIUM
                        </th>
                        <th className="t-head" scope="col">
                          DAILY ACTIVITY
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table-dark">
                      {data?.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.createdAt}</td>
                          <td>{item.createdAt}</td>
                          <td>
                            <button onClick={() => openModal(item.imagekey)}>
                              View Images
                            </button>
                          </td>
                          <td>{item.medium}</td>
                          <td>{item.desc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {isModalOpen && (
              <ModalBackground onClick={closeModal}>
                <ModalContainer onClick={(e) => e.stopPropagation()}>
                  {currentImages.length > 0 ? (
                    <Carousel showArrows={true} showThumbs={false}>
                      {currentImages?.map((image, index) => (
                        <ImageWrapper key={index}>
                          <img src={imageURL(image)} alt={`img-${index}`} />
                        </ImageWrapper>
                      ))}
                    </Carousel>
                  ) : (
                    <p>No images uploaded.</p>
                  )}
                  <CloseButton onClick={closeModal}>Close</CloseButton>
                </ModalContainer>
              </ModalBackground>
            )}
          </CrmAdminFranchiseViewContainer>
        </ImageList>
      </Dialog>
    </ProfileContainer>
  );
}
else{
  return(
    <RedirectPage/>
  )
}
};

const ProfileContainer=styled.div`
    display: flex;
    color: white;
    height: 100vh;
`
const Text = styled(Typography)`
  color: white;
`;
export default StudentProfille;

const CrmAdminFranchiseViewContainer = styled.div`
  .scroll {
    overflow-y: auto;
    max-height: 87vh; /* Adjust as needed */
  }
  .scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .text {
    margin-left: 0.6rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: white;
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .t-head {
    text-align: center;
  }
  td {
    text-align: center;
  }
  #table-container {
    background-color: #25272d;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 10px;
    height: auto;
    width: auto;
    overflow-y: scroll;
  }
  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }
  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
  td {
    padding: 2rem !important;
  }
  /* Add media queries for responsiveness */
  @media (max-width: 768px) {
    width: auto;
    .scroll {
      max-height: 60vh;
    }
  }
  @media (max-width: 576px) {
    width: auto;
    .scroll {
      max-height: 30vh;
    }
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 40%;
  max-height: 40%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  background-color: #f00d88;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  transition: background-color 0.3s;
  margin-top: 1rem;

  &:hover {
    background-color: #d00d88;
  }
`;

const ImageWrapper = styled.div`
  max-width: auto;
  max-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 50%;
    max-height: 50%;
    object-fit: cover;
  }
`;
