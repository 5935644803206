import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { URL as url } from "../../../assets/mocData/config";
import { enqueueSnackbar } from "notistack";
import { handleImageSubmit, imageURL, postData, putData } from "../../../Api";
import axios from "axios";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "styled-components";
import { Button } from "@mui/material";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
import { Country, State, City } from "country-state-city";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormControl,
  Autocomplete,
  Paper,
  FormControlLabel,
  FormLabel,
  InputBase,
  createTheme,
  ThemeProvider,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f00d88", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // White color for input text
          backgroundColor: "black", // Background color for filled input
          "&:before": {
            borderColor: "white", // Initial border color white
          },
          "&:hover:before": {
            borderColor: "#f00d88", // Border color on hover
          },
          "&.Mui-focused:before": {
            borderColor: "#f00d88", // Border color when focused
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Background color when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // Gray color for label
          "&.Mui-focused": {
            color: "#f00d88", // Pink color for label when focused
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white", // White color for the dropdown icon
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#25272d", // Dark gray background for dropdown
          color: "#ffffff", // White text color for dropdown items
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#ffffff",// Pink color for the dropdown icon
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#25272d", // Gray background
          color: "#ffffff", // White text color
          "&:hover": {
            backgroundColor: "#f00d88", // Pink background on hover
          },
        },
      },
    },
  },
});
function AddVipForm({ addVipData }) {
  const {
    generateRandomPassword,
    userToken,
    userRole,
    profileData,
    transferData,
  } = useGlobalContext();

  const [checked, setChecked] = useState("manual_add");
  const [referId, setReferId] = useState(addVipData._id);
  const [jobDataAll, setJobDataAll] = useState([]);
  const [jobData, setJobData] = useState({
    jobDescription: "",
    companyName: "",
  });
  const [allEducation, setAllEducation] = useState([]);
  const [education, setEducation] = useState({
    degree: "",
    collegeName: "",
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedValue, setSelectedValue] = useState("a");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "status",
    inputProps: { "aria-label": item },
  });
  useEffect(() => {
    if (selectedCountry?.isoCode) {
      State?.getStatesOfCountry(selectedCountry?.isoCode);
    }
  }, [selectedCountry]);
  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#000", // Dark background for the input
      color: "#fff",
      border: "1px solid black",
      borderRadius: "0.375rem",
      margin: "0.25rem",
      width: "100%",
      padding: "0.75rem",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333", // Dark background for the dropdown
      color: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#555" : "#333", // Darker when selected
      color: "#fff",
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Ensures the selected option text is white
    }),
  };
  const Checked = (event) => {
    setChecked(event.target.value);
    console.log(event.target.value);
  };

  const [data, setData] = useState({
    imageACCESSKEY: " ",
    userType: "vip",
    verified: false,
    otherDetails: {
      profileDataCompletion: false,
      vipStatus: "Beginner VIP",
      
    },
   
  });
  console.log(data);
 
  
  console.log(data);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [password, setPassword] = useState("");
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const objectUrl = URL.createObjectURL(file);
      console.log(objectUrl);
      setPreviewUrl(objectUrl);
    }
    await handleImageSubmit(file, setData, data);
  };
  const getData = (isoDate) => {
    if (isoDate == null) {
      return null;
    } else {
      const dateObject = parseISO(isoDate);
      const formattedDate = format(dateObject, "yyyy-MM-dd");
      return formattedDate;
    }
  };
  const addJobData = (dataJ) => {
    console.log(dataJ);
    if (dataJ.jobDescription == "" || dataJ.companyName == "") {
      enqueueSnackbar("Fill the detail job description and company name", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return null;
    }
    setJobDataAll([...jobDataAll, dataJ]);
    setData({
      ...data,
      jobDescription: [...jobDataAll, dataJ],
    });
    setJobData({
      jobDescription: "",
      companyName: "",
    });
  };
  function removeElementByCondition(jobDescription) {
    // Create a new array with elements that do not match the specified company name
    const filteredArray = jobDataAll.filter(
      (element) => element.jobDescription !== jobDescription
    );

    console.log(filteredArray); // This will show the new array without the specified company

    setJobDataAll(filteredArray);
  }
  function removeElementByConditionEducation(degree) {
    // Create a new array with elements that do not match the specified company name
    const filteredArray = allEducation.filter(
      (element) => element.degree !== degree
    );

    console.log(filteredArray); // This will show the new array without the specified company

    setAllEducation(filteredArray);
  }



   const addEducationData = (dataJ) => {
     console.log(dataJ);
     if (dataJ.degree == "" || dataJ.collegeName == "") {
       enqueueSnackbar("Fill the detail degree and college Name", {
         variant: "warning",
         autoHideDuration: 1000,
       });
       return null;
     }
     setAllEducation([...allEducation, dataJ]);
     setData({
       ...data,
       educationDetails: [...allEducation, dataJ],
     });
     setEducation({
       degree: "",
       collegeName: "",
     });
   };
   

   const validateFormFranchiseMemberData = (data) => {
    // Validation for Staff Name
    if (!data.userName || data.userName.trim() === "") {
      enqueueSnackbar(" Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Age
    const age = parseInt(data.age, 10);
    if (isNaN(age) || age < 18 || age > 100) {
      enqueueSnackbar("Please enter a valid age between 18 and 100.", { variant: "warning" });
      return false;
    }
  
    if (!data.bloodGroup || data.bloodGroup.trim() === "") {
      enqueueSnackbar("Please enter a valid blood group.", { variant: "warning" });
      return false;
    }
  
    // Validation for Date of Birth
    if (!data.dateOfBirth || isNaN(new Date(data.dateOfBirth).getTime())) {
      enqueueSnackbar("Please enter a valid Date of Birth.", { variant: "warning" });
      return false;
    }
  
    // Validation for Gender
    const validGenders = ["Male", "Female", "Other"];

  // Validation for Gender
  if (!data.gender || data.gender === "" || !validGenders.includes(data.gender)) {
    enqueueSnackbar("Please select a valid gender.", { variant: "warning" });
    return false;
  }
  
    // Validation for Father Name
    if (!data.fatherName || data.fatherName.trim() === "") {
      enqueueSnackbar("Father's Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Mother Name
    if (!data.motherName || data.motherName.trim() === "") {
      enqueueSnackbar("Mother's Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Degree
    if ((education.degree === "" || education.collegeName === "")&&allEducation.length === 0) {
      enqueueSnackbar("Please fill in both Degree and College Name fields.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;  // Stop validation if fields are empty
    }
  
    // Ensure at least one education entry is added
    if (allEducation.length === 0) {
      enqueueSnackbar("Please add at least one education entry.", {
        variant: "warning",
        autoHideDuration: 1500,
      });
      return false;  // Prevent form submission if no education entry exists
    }
    
    if (!data.newForWorking) {
      enqueueSnackbar("Please select whether the person is new for working.", { variant: "warning" });
      return false;
    }
  
    const { jobDescription, companyName } = jobData;  // If the user is not new for working, validate job details
   // Validation for Job Details (if the user is not new for working)
if (data.newForWorking === "no") {
  if ((jobDescription.trim() === "" || companyName.trim() === "") && jobDataAll.length === 0) {
    enqueueSnackbar("Please fill in both Job Description and Company Name fields.", {
      variant: "warning",
      autoHideDuration: 1000,
    });
    return false; // Stop further execution
  }
  if (jobDataAll.length === 0) {
    enqueueSnackbar("Please add at least one job description entry.", {
      variant: "warning",
      autoHideDuration: 1500,
    });
    return false;  // Prevent form submission if no education entry exists
  }
    
}
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    if (!data.email || !emailRegex.test(data.email)) {
      enqueueSnackbar("Please enter a valid email address.", { variant: "warning" });
      return false;
    }
  
    // Contact Phone Number validation
    const phoneRegex = /^[0-9]{10}$/; // Adjust as per your requirement
    if (!data.phoneNumber || !phoneRegex.test(data.phoneNumber)) {
      enqueueSnackbar("Please enter a valid phone number.", { variant: "warning" });
      return false;
    }
  
    // Whatsapp Number validation
    if (!data.whatsappNumber || !phoneRegex.test(data.whatsappNumber)) {
      enqueueSnackbar("Please enter a valid WhatsApp number.", { variant: "warning" });
      return false;
    }
    if (!data.doorFlatNumber) {
      enqueueSnackbar("Please enter your Door/Flat number.", { variant: "warning" });
      return false;
    }
  
    // Street Name validation
    if (!data.streetName) {
      enqueueSnackbar("Please enter the street name.", { variant: "warning" });
      return false;
    }
  
    // Landmark validation (Optional)
    // if (!data.landMark) {
    //   enqueueSnackbar("Please enter a landmark.", { variant: "warning" });
    //   return false;
    // }
  
    // Country validation
    if (!data.country) {
      enqueueSnackbar("Please select a country.", { variant: "warning" });
      return false;
    }
  
    // State validation
    if (!data.state) {
      enqueueSnackbar("Please select a state.", { variant: "warning" });
      return false;
    }
  
    // District validation
    if (!data.district) {
      enqueueSnackbar("Please select a district.", { variant: "warning" });
      return false;
    }
  
    // Pincode validation
    const pinCodeRegex = /^[0-9]{6}$/; // Adjust the regex based on country-specific formats
    if (!data.pinCode || !pinCodeRegex.test(data.pinCode)) {
      enqueueSnackbar("Please enter a valid pincode.", { variant: "warning" });
      return false;
    }
    if (!data.bankAccountName) {
      enqueueSnackbar("Please enter your Bank Account Name.", { variant: "warning" });
      return false;
    }
  
    // Branch Name validation
    if (!data.bankBranchName) {
      enqueueSnackbar("Please enter your Branch Name.", { variant: "warning" });
      return false;
    }
  
    // IFSC Code validation
    const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/; // Adjust based on actual IFSC code format
    if (!data.ifscCode ) {
      enqueueSnackbar("Please enter a valid IFSC Code.", { variant: "warning" });
      return false;
    }
  
    // Bank Account Number validation
    if (!data.bankAccountNumber) {
      enqueueSnackbar("Please enter your Bank Account Number.", { variant: "warning" });
      return false;
    }
  
    // PAN Card Number validation
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // Adjust based on actual PAN card format
    if (!data.panCardNumber ) {
      enqueueSnackbar("Please enter a valid PAN Card Number.", { variant: "warning" });
      return false;
    }
   
   
  
    // Check for duplicates in workingOption
    const uniqueOptions = new Set(data.workingOption);
    if (uniqueOptions.size !== data.workingOption.length) {
      enqueueSnackbar("Duplicate concepts are not allowed.", { variant: "warning" });
      return false;
    }


    if (!data.password) {
      enqueueSnackbar("Please generate password.", { variant: "warning" });
      return false;
    }

    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?([\\w\\d\\-]+\\.)+[a-z]{2,}(\\/[^\\s]*)?$", 
      "i"
    );
  
    const invalidUrls = [];
    if (data.instagram && !urlPattern.test(data.instagram)) {
      invalidUrls.push("Instagram");
    }
    if (data.facebook && !urlPattern.test(data.facebook)) {
      invalidUrls.push("Facebook");
    }
    if (!data.instagram) {
      enqueueSnackbar("Please provide a instagram link.", { variant: "warning" });
      return false;
    }
    if (!data.facebook) {
      enqueueSnackbar("Please provide a facebook link.", { variant: "warning" });
      return false;
    }
  
    if (invalidUrls.length > 0) {
      enqueueSnackbar(`Invalid URL(s) for: ${invalidUrls.join(", ")}`, { variant: "warning" });
      return false;
    }

 

  
    return true;
  };

  const handleClickGender = (e) => {
    console.log([e.target.name],e.target.value )
    setData({ ...data,  [e.target.name]: e.target.value }); // Update gender state
  };

  const addVip = async (e, data) => {
    e.preventDefault();
    setIsLoading(false);
    let headers = {
      userToken: userToken,
      userRole: userRole,
    };
    console.log(data);


    if (selectedCity) {
      data.country = selectedCountry.name;
      data.state = selectedState.name;
      data.district = selectedCity.name;
    }

    const isValid = validateFormFranchiseMemberData(data);

    if (!isValid){
      return
    }




    if (data.joinedDate == "") {
      data.joinedDate = Date.now();
    } else if (data.monthlySalary == "") {
      data.monthlySalary = "0";
    }
    data.joinedDate = Date.now();

    data["otherDetails"] = {
      vip_status: "Beginner VIP",
      pinCode: [],
      firstBusinessDate: "",
    };
    data.status = "active";
    try {
      if (!data.country) {
        data.country = selectedCountry.name;
        data.state = selectedState.name;
        data.district = selectedCity.name;
      }

      if (userRole == "admin" || userRole == "hos") {
        if (data.imageACCESSKEY == " ") {
          enqueueSnackbar("Upload the image", { variant: "warning" });
          return null;
        }
        if (userRole == "hos") {
          headers = {
            userToken: userToken,
            userRole: "admin",
          };
        }
        if (data.workingOption[0] == "vipFranchise") {
          await putData(`${url}/admin/vip/vipfranchise`, data, headers);
        } else {
          let response = await postData(`${url}/auth/register`, data, headers);
          console.log("data", response);
        }

        if (data.workingOption.length == 0) {
          try {
            let r = await axios.delete(`${url}/vip/refer/${referId}`, {
              headers: {
                Authorization: `Bearer ${headers.userToken}`,
                role: headers.userRole,
              },
            });
            console.log(r);
          } catch (error) {
            console.log(error);
          }
        }

        setData({
          verified: false,
          userType: "vip",
          workingOption: [],
          imageACCESSKEY: " ",
        });
      } else {
        let response = await postData(`${url}/vip/refer`, data, headers);
        console.log(response);
        setData({
          verified: false,
          userType: "vip",
          workingOption: [],
          imageACCESSKEY: " ",
        });
      }

      window.location.reload();
    } catch (error) {
      enqueueSnackbar(`${error.response.data.error}`, { variant: "error" });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const FormData = (e) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    if (e.target.name == "profileImage") {
    } else if (
      e.target.name == "jobDescription" ||
      e.target.name == "companyName"
    ) {
      setJobData({ ...jobData, [e.target.name]: e.target.value });
    } else if (e.target.name == "degree" || e.target.name == "collegeName") {
      setEducation({ ...education, [e.target.name]: e.target.value });
    } else if (e.target.name == "") {
      return;
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (userRole === "admin" || userRole == "hos") {
      setData({
        ...data,
        password: password,
        verified: false,
      });
    } else {
      setData({
        ...data,
        refferalID: profileData.profileData.user_id,
        password: password,
        verified: false,
      });
      setReferId(profileData.profileData.user_id);
    }
  }, [password]);
  useEffect(() => {
    let details;
    let keyValues = ["_id", "__v", "createdAt", "updatedAt"];

    if (transferData.userName !== undefined) {
      console.log(transferData);
      addVipData = { ...transferData };
    }

    if (addVipData!== []) {
      details = Object.keys(addVipData)
        ?.filter((objKey) => !keyValues.includes(objKey))
        ?.reduce((newObj, key) => {
          newObj[key] = addVipData[key];
          return newObj;
        }, {});
    }

    if (userRole == "admin" || userRole == "hos") {
      if (addVipData.length !== 0) {
        setData({
          ...details,
          otherDetails: {
            profileDataCompletion: false,
            isVip: false,
          },
        });
        setAllEducation([...details.educationDetails]);
        setJobDataAll([...details.jobDescription]);

        console.log(
          "----",
          details.educationDetails,
          ...details.jobDescription
        );
      } else {
        setData({
          imageACCESSKEY: "",
          newForWorking: "",
          password: "",
          userName: "",
          email: "",
          userType: "vip",
          verified: false,
          workingOption: [],
          otherDetails: {
            profileDataCompletion: false,
            isVip: false,
          },
        });
        setReferId(profileData.profileData.user_id);
      }
    }
  }, []);
  const sendMailForManualAdding = async () => {
    try {
      setIsLoading(true);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (userRole == "admin" || userRole == "hos") {
        if(data.userName=="")
        {
          enqueueSnackbar("Username is required",{variant:"warning",
            autoHideDuration:1000
          }
        )
        return
        }else if (!data.email || !emailRegex.test(data.email)) {
          enqueueSnackbar("Please enter a valid email address.", { variant: "warning" });
          return false;
        }else if(data.password=="")
            {
              enqueueSnackbar("Password is required",{variant:"warning",
                autoHideDuration:1000
              }
            )
            return
            }
            else if(data.phoneNumber=="")
              {
                enqueueSnackbar("Phone number is required",{variant:"warning",
                  autoHideDuration:1000
                }
              )
              return
              }
              else 
              {
                let response = await axios.post(`${url}/admin/addautouser`, data, {
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                    role: "admin",
                  },
                });
                setData({
                  imageACCESSKEY: "",
                  newForWorking: "",
                  password: "",
                  refferalID: "",
                  userName: "",
                  email: "",
                  userType: "vip",
                  verified: false,
                  workingOption: [],
                  refferalID: null,
                  phoneNumber: "",
                  jobDescription: "",
                  companyName: "",
                });
                console.log(response);
              }
        
      }
    } catch (error) {
      // enqueueSnackbar(`${error.message}`, {
      //   variant: "error",
      //   autoHideDuration: 1000,
      // });
      console.log(error);
    }finally{
      setIsLoading(false);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    return (
      <div
        style={{
          width: "100vh",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
          backgroundColor: "#18171b",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <AddVipContainer>
      <ThemeProvider theme={theme}>
        <div class="container">
          <div class="row">
            <div class="col col-9" style={{ marginTop: "1rem" }}>
              <div id="form-container" class="m-1">
                <form
                  class="scroll"
                  style={{
                    height: "auto",
                    overflowY: "scroll",
                    paddingTop: "1rem",
                  }}
                  action=""
                >
                  <div class="container">
                    <div class="row">
                      <span class="col-3">Options</span>
                      <div class="col-9 d-flex flex-column px-3">
                        <div className="d-flex justify-content-around">
                          <div class="form-check">
                            <input
                              onClick={(e) => Checked(e)}
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value={"manual_add"}
                              id="flexRadioDefault1"
                              checked={true}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              <p>Manual Add</p>
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              onClick={(e) => Checked(e)}
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value={"auto_add"}
                              id="flexRadioDefault2"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              <p>Auto Add</p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>

        {checked == "manual_add" ? (
          <>
            <form
              onChange={FormData}
              class="container"
              style={{ position: "relative" }}
            >
              <div class="row d-md-flex flex-column-reverse flex-md-row">
                <div class="col col-12 col-md-9">
                  <div id="form-container" class="m-1">
                    <form
                      class="scroll"
                      style={{
                        height: "85vh",
                        overflowY: "scroll",
                        paddingTop: "1rem",
                      }}
                      action=""
                    >
                      <div class="container"></div>

                      <div class="container">
                        <div class="row">
                          <hr />
                          <span class="col-md-3 col-sm-12 col-sm-12">
                            Personal Information
                          </span>
                          <div className="col-12 d-flex flex-column px-3">
                            <div style={{ width: "100%" }}>
                              <span className="d-flex flex-column flex-md-row justify-content-between">
                                <TextField
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem", // Default margin
                                    marginBottom: "1rem", // Default spacing below
                                  }}
                                  id="filled-basic"
                                  variant="filled"
                                  label=" Name"
                                  name="userName"
                                  value={data.userName}
                                  fullWidth
                                />
                              </span>

                              <span className="d-flex flex-column flex-sm-row justify-content-between">
                                <TextField
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem",
                                    marginBottom: "1rem",
                                  }}
                                  variant="filled"
                                  label="Age"
                                  name="age"
                                  value={data.age}
                                  fullWidth
                                />
                                <TextField
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem",
                                    marginBottom: "1rem",
                                  }}
                                  variant="filled"
                                  label="Blood Group"
                                  name="bloodGroup"
                                  value={data.bloodGroup}
                                  fullWidth
                                />
                              </span>

                              <span className="d-flex flex-column flex-md-row justify-content-between">
                                <TextField
                                  label="Date of Birth (dd-mm-yyyy)"
                                  variant="filled"
                                  id="filled-basic"
                                  type="date"
                                  
                                  name="dateOfBirth"
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem",
                                    marginBottom: "1rem",
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    style: {
                                      colorScheme: "dark", // For dark mode styling
                                      width: "100%",
                                    },
                                  }}
                                  fullWidth
                                  className="form-field"
                                />
                               <FormControl
  fullWidth
  variant="filled"
  style={{
    margin: "0.5rem",
    marginBottom: "1rem",
  }}
>
  <InputLabel
    shrink={Boolean(!data.gender)} // Conditionally shrink/hide the label based on gender value
  >
    {data.gender ? "" : "Gender"} {/* Input label visible only when gender is null */}
  </InputLabel>
  
  <Select
    name="gender"
    value={data.gender || ""} // Ensure value is empty string if gender is null
    onChange={handleClickGender}
    displayEmpty // Enable displayEmpty to show placeholder when value is empty
    renderValue={(selected) => selected ? selected : "Select Gender"} // Display placeholder
  >
    <MenuItem value="">
      <em>Select Gender</em> {/* Placeholder text for an empty selection */}
    </MenuItem>
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </Select>
</FormControl>

                              </span>
                              <span className="d-flex flex-column flex-md-row justify-content-between">
                                <TextField
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem",
                                    marginBottom: "1rem",
                                  }}
                                  variant="filled"
                                  label="Father's Name"
                                  name="fatherName"
                                  value={data.fatherName}
                                  fullWidth
                                />
                                <TextField
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem",
                                    marginBottom: "1rem",
                                  }}
                                  variant="filled"
                                  label="Mother's Name"
                                  name="motherName"
                                  value={data.motherName}
                                  fullWidth
                                />
                              </span>

                              <span className="d-flex flex-column flex-md-row justify-content-between">
                                {allEducation?.map((education) => {
                                  return (
                                    <div className="m-2">
                                      <Button variant="contained">
                                        {education.degree}--
                                        {education.collegeName}
                                        <Icon
                                          onClick={() => {
                                            removeElementByConditionEducation(
                                              education.degree
                                            );
                                          }}
                                          style={{
                                            padding: "0.5rem",
                                          }}
                                          icon={cross}
                                        />
                                      </Button>
                                    </div>
                                  );
                                })}
                              </span>
                              <span className="d-flex flex-column flex-md-row justify-content-between">
                                <TextField
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem",
                                    marginBottom: "1rem",
                                  }}
                                  variant="filled"
                                  label="Degree"
                                  name="degree"
                                  value={education.degree}
                                  fullWidth
                                />
                                <TextField
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    margin: "0.5rem",
                                    marginBottom: "1rem",
                                  }}
                                  variant="filled"
                                  label="College Name"
                                  name="collegeName"
                                  value={education.collegeName}
                                  fullWidth
                                />
                                <span className="m-2 d-flex justify-content-center align-items-center">
                                  <Button
                                    onClick={() => addEducationData(education)}
                                    variant="outlined"
                                    color="success"
                                    size="small"
                                    sx={{
                                      borderColor: "green",
                                      color: "green",
                                      padding: "2px 8px",
                                      minHeight: "24px",
                                      fontSize: "0.75rem",
                                      lineHeight: "1",
                                      "&:hover": {
                                        borderColor: "darkgreen",
                                        backgroundColor: "rgba(0, 128, 0, 0.1)",
                                      },
                                    }}
                                  >
                                    Add
                                  </Button>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container mt-4">
                        <div class="row">
                          <div className="col-md-3 col-12">
                            <span>Career Details</span>
                          </div>
                          <div className="col-md-9 col-12 d-flex flex-column px-3">
                            <div className="d-flex flex-column flex-md-row align-items-center mb-3">
                              <p className="mb-0 me-3">Currently Unemployed</p>
                              <div className="d-flex align-items-center me-3">
                                <input
                                  style={{
                                    margin: "1rem",
                                    width: "1rem",
                                    height: "1rem",
                                    border: "1px solid black",
                                  }}
                                  className="me-2"
                                  checked={data.newForWorking === "yes"}
                                  type="radio"
                                  id="yes"
                                  value="yes"
                                  name="newForWorking"
                                />
                                <label htmlFor="yes" className="mb-0">
                                  Yes
                                </label>
                              </div>
                              <div className="d-flex align-items-center me-3">
                                <input
                                  style={{
                                    margin: "1rem",
                                    width: "1rem",
                                    height: "1rem",
                                    border: "1px solid black",
                                  }}
                                  className="me-2"
                                  type="radio"
                                  id="no"
                                  value="no"
                                  checked={data.newForWorking === "no"}
                                  name="newForWorking"
                                />
                                <label htmlFor="no" className="mb-0">
                                  No
                                </label>
                              </div>
                            </div>
                            {data.newForWorking == "no" && (
                              <>
                                <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                                  {jobDataAll?.map((job) => (
                                    <div
                                      className="m-2"
                                      key={job.jobDescription}
                                    >
                                      <Button
                                        variant="contained"
                                        color="success"
                                        style={{ padding: "0.5rem 1rem" }}
                                      >
                                        {job.jobDescription} --{" "}
                                        {job.companyName}
                                        <Icon
                                          onClick={() => {
                                            removeElementByCondition(
                                              job.jobDescription
                                            );
                                          }}
                                          style={{ padding: "0.5rem" }}
                                          icon={cross}
                                        />
                                      </Button>
                                    </div>
                                  ))}
                                </div>
                                <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                                  <TextField
                                    style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      margin: "0.5rem",
                                      border: "1px solid black",
                                    }}
                                    variant="filled"
                                    label="Job Description"
                                    name="jobDescription"
                                    value={jobData.jobDescription}
                                    fullWidth
                                  />
                                  <TextField
                                    style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      margin: "0.5rem",
                                      border: "1px solid black",
                                    }}
                                    variant="filled"
                                    label="Company Name"
                                    name="companyName"
                                    value={jobData.companyName}
                                    fullWidth
                                  />
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                  <Button
                                    onClick={() => addJobData(jobData)}
                                    variant="outlined"
                                    color="success"
                                    size="small"
                                    sx={{
                                      borderColor: "green",
                                      color: "green",
                                      padding: "2px 8px",
                                      minHeight: "24px",
                                      fontSize: "0.75rem",
                                      lineHeight: "1",
                                      "&:hover": {
                                        borderColor: "darkgreen",
                                        backgroundColor: "rgba(0, 128, 0, 0.1)",
                                      },
                                    }}
                                  >
                                    Add
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container mt-4">
                        <div class="row">
                          <span class="col-md-3 col-sm-12 col-sm-12">
                            Contact Details
                          </span>
                          <div className="col-12 col-md-9 d-flex flex-column px-3">
                            <div className="d-flex flex-column flex-md-row align-items-center">
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem 0", // Adjusted margin for spacing
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Contact Email"
                                name="email"
                                value={data.email}
                                fullWidth
                              />
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem 0", // Adjusted margin for spacing
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Contact Phone Number"
                                name="phoneNumber"
                                value={data.phoneNumber}
                                fullWidth
                              />
                            </div>

                            <div className="d-flex flex-column flex-md-row">
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem 0", // Adjusted margin for spacing
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Whatsapp"
                                value={data.whatsappNumber}
                                name="whatsappNumber"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container">
                        <div class="row">
                          <span class="col-3 col-md-3 col-sm-12 col-sm-12">
                            Address
                          </span>
                          <div className="col-12 col-md-9 d-flex flex-column px-3">
                            {/* First Row */}
                            <div className="row">
                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Door/Flat No"
                                  name="doorFlatNumber"
                                  value={data.doorFlatNumber}
                                  fullWidth
                                />
                              </div>
                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Street Name"
                                  name="streetName"
                                  value={data.streetName}
                                  fullWidth
                                />
                              </div>
                            </div>

                            {/* Second Row */}
                            <div className="row">
                              <div className="col-12 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Land mark"
                                  name="landMark"
                                  value={data.landMark}
                                  fullWidth
                                />
                              </div>
                            </div>

                            {/* Third Row with Autocomplete */}

                            {/* Fourth Row with Autocomplete and TextField */}
                            <div className="row">
                              {data.district ? (
                                <>
                                  <div className="col-12 col-md-6 mb-3">
                                    <TextField
                                      style={{
                                        width: "100%",
                                        border: "1px solid black",
                                      }}
                                      variant="filled"
                                      label="Country"
                                      name="country"
                                      value={data.country}
                                      fullWidth
                                    />
                                    <TextField
                                      style={{
                                        width: "100%",
                                        border: "1px solid black",
                                      }}
                                      variant="filled"
                                      label="District"
                                      name="district"
                                      value={data.district}
                                      fullWidth
                                    />
                                  </div>

                                  <div className="col-12 col-md-6 mb-3">
                                    <TextField
                                      style={{
                                        width: "100%",
                                        border: "1px solid black",
                                      }}
                                      variant="filled"
                                      label="State"
                                      name="state"
                                      value={data.state}
                                      fullWidth
                                    />
                                    <TextField
                                      style={{
                                        width: "100%",
                                        border: "1px solid black",
                                      }}
                                      variant="filled"
                                      label="Pincode"
                                      name="pinCode"
                                      value={data.pinCode}
                                      fullWidth
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                      <Autocomplete
                                        options={Country.getAllCountries()}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedCountry}
                                        onChange={(event, newValue) =>
                                          setSelectedCountry(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            label="Country"
                                            placeholder="Country"
                                            InputLabelProps={{
                                              style: { color: "gray" },
                                            }}
                                            sx={{
                                              "& .MuiFilledInput-root": {
                                                backgroundColor: "black",
                                                color: "white",
                                              },
                                              "& .MuiFilledInput-root:hover:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiFilledInput-root.Mui-focused:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                            }}
                                          />
                                        )}
                                        PaperComponent={(props) => (
                                          <Paper
                                            {...props}
                                            sx={{
                                              backgroundColor: "white",
                                              color: "black",
                                            }}
                                          />
                                        )}
                                        sx={{
                                          "& .MuiAutocomplete-option": {
                                            "&:hover": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                            "&[aria-selected='true']": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                      <Autocomplete
                                        options={
                                          State?.getStatesOfCountry(
                                            selectedCountry?.isoCode
                                          ) || []
                                        }
                                        getOptionLabel={(option) => option.name}
                                        value={selectedState}
                                        onChange={(event, newValue) =>
                                          setSelectedState(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            label="State"
                                            placeholder="State"
                                            InputLabelProps={{
                                              style: { color: "gray" },
                                            }}
                                            sx={{
                                              "& .MuiFilledInput-root": {
                                                backgroundColor: "black",
                                                color: "white",
                                              },
                                              "& .MuiFilledInput-root:hover:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiFilledInput-root.Mui-focused:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                            }}
                                          />
                                        )}
                                        PaperComponent={(props) => (
                                          <Paper
                                            {...props}
                                            sx={{
                                              backgroundColor: "white",
                                              color: "black",
                                            }}
                                          />
                                        )}
                                        sx={{
                                          "& .MuiAutocomplete-option": {
                                            "&:hover": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                            "&[aria-selected='true']": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {/* Fourth Row with Autocomplete and TextField */}
                                  <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                      <Autocomplete
                                        options={
                                          selectedState
                                            ? City.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                              )
                                            : []
                                        }
                                        getOptionLabel={(option) => option.name}
                                        value={selectedCity}
                                        onChange={(event, newValue) =>
                                          setSelectedCity(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            label="District"
                                            placeholder="District"
                                            InputLabelProps={{
                                              style: { color: "gray" },
                                            }}
                                            sx={{
                                              "& .MuiFilledInput-root": {
                                                backgroundColor: "black",
                                                color: "white",
                                              },
                                              "& .MuiFilledInput-root:hover:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiFilledInput-root.Mui-focused:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                            }}
                                          />
                                        )}
                                        PaperComponent={(props) => (
                                          <Paper
                                            {...props}
                                            sx={{
                                              backgroundColor: "white",
                                              color: "black",
                                            }}
                                          />
                                        )}
                                        sx={{
                                          "& .MuiAutocomplete-option": {
                                            "&:hover": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                            "&[aria-selected='true']": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                      <TextField
                                        style={{
                                          width: "100%",
                                          border: "1px solid black",
                                        }}
                                        variant="filled"
                                        label="Pincode"
                                        name="pinCode"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container mt-4">
                        <div class="row">
                          <span class="col-md-3 col-sm-12 col-sm-12">
                            Account Details
                          </span>
                          <div className="col-9 d-flex flex-column px-3">
                            {/* Row for larger screens, stacking on smaller screens */}
                            <div className="row">
                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Bank Account Name"
                                  name="bankAccountName"
                                  value={data.bankAccountName}
                                  fullWidth
                                />
                              </div>
                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Branch Name"
                                  name="bankBranchName"
                                  value={data.bankBranchName}
                                  fullWidth
                                />
                              </div>
                            </div>

                            {/* Row for larger screens, stacking on smaller screens */}
                            <div className="row">
                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="IFSC Code"
                                  name="ifscCode"
                                  value={data.ifscCode}
                                  fullWidth
                                />
                              </div>
                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Bank Account Number"
                                  name="bankAccountNumber"
                                  value={data.bankAccountNumber}
                                  fullWidth
                                />
                              </div>
                            </div>

                            {/* Full-width text field */}
                            <div className="row">
                              <div className="col-12">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="PAN Card Number"
                                  name="panCardNumber"
                                  value={data.panCardNumber}
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container">
                        <div class="row">
                          <span class="col-md-3 col-sm-12 col-sm-12">
                            Password
                          </span>
                          <div className="col-12 col-md-9 d-flex flex-column px-3">
                            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                              <TextField
                                style={{
                                  width: "100%", // Full width for the TextField, responsive adjustments will be handled by the container
                                  margin: "3%",
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Enter password"
                                name="password"
                                value={password}
                                fullWidth
                              />
 
                              <Button
                                variant="outlined"
                                color="success"
                                size="small"
                                onClick={() =>
                                  setPassword(generateRandomPassword(8))
                                }
                                sx={{
                                  width: {
                                    xs: "100%", // 100% width on extra small screens
                                    sm: "auto", // Auto width on small screens and above
                                    md: "25%", // 25% width on medium screens and above
                                  },
                                  marginTop: {
                                    xs: "1rem", // Top margin for extra small screens
                                    sm: "0", // No top margin for small screens and above
                                  },
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Generate
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="container">
                        <div className="row mb-3">
                          <div className="col-12 col-md-3 d-flex align-items-center">
                            <span>Media Link</span>
                          </div>
                          <div className="col-12 col-md-9 d-flex flex-column px-3">
                            <TextField
                              variant="filled"
                              label="Instagram"
                              name="instagram"
                              value={data.instagram}
                              fullWidth
                              style={{
                                margin: "3%",
                                border: "1px solid black",
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-3"></div>
                          <div className="col-12 col-md-9 d-flex flex-column px-3">
                            <TextField
                              variant="filled"
                              label="Facebook"
                              name="facebook"
                              value={data.facebook}
                              fullWidth
                              style={{
                                margin: "3%",
                                border: "1px solid black",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div class="container mt-3">
                        <div class="row justify-content-center">
                          <div class="col-12 d-flex justify-content-center">
                            <div class="d-flex flex-column flex-sm-row align-items-center">
                              <button
                                onClick={(e) => {
                                  addVip(e, data);
                                }}
                                class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-course-btn"
                              >
                                Add Vip
                              </button>
                              <button class="btn btn-outline-danger btn-sm cancel-btn">
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col col-12 col-md-3">
                  <div id="profile-container" class="m-1">
                    <div id="profile-img">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                        name="profileImage"
                      />
                      {data.imageACCESSKEY ? (
                        <img
                          id="image"
                          src={imageURL(data.imageACCESSKEY)}
                          alt=""
                        />
                      ) : previewUrl !== "" ? (
                        <img id="image" src={previewUrl} alt="" />
                      ) : (
                        <img
                          src="https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
                          alt=""
                        />
                      )}
                    </div>
                    <div class="upld">
                      <b>Upload</b>
                    </div>
                    <h5>Add Profile Photo</h5>
                    <h5 style={{ textAlign: "center" }}>
                      <i class="fa-solid fa-id-badge"></i> Set as <b>Active</b>
                    </h5>
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <></>
        )}

        {checked == "auto_add" ? (
          <form onChange={FormData}>
            <hr />
            <div class="container">
              <div class="row">
                <span class="col-3">Refferal Details</span>
                <div class="col-9 d-flex flex-column px-12">
                  <br />

                  <input
                    style={{
                      width: "100%",
                      border: "1px solid black",
                    }}
                    class="p-3 bg-black rounded-3"
                    input
                    type="text"
                    required={true}
                    name="userName"
                    placeholder="Name"
                    value={data.userName}
                  />
                  <br />

                  <input
                    style={{
                      width: "100%",
                      border: "1px solid black",
                    }}
                    class="p-3 bg-black rounded-3"
                    input
                    type="text"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={data.phoneNumber}
                  />
                  <br />

                  <input
                    style={{
                      width: "100%",
                      border: "1px solid black",
                    }}
                    class="p-3 bg-black rounded-3"
                    input
                    type="text"
                    placeholder="Contact Email"
                    name="email"
                    value={data.email}
                  />
                </div>
              </div>
            </div>
            <hr />

            <div class="container">
              <div class="row">
                <span class="col-3">Password</span>
                <div class="col-9 d-flex flex-column px-3">
                  <span
                    style={{ width: "100%" }}
                    class="d-flex justify-content-between"
                  >
                    <input
                      style={{
                        width: "45%",
                        border: "1px solid black",
                      }}
                      class="p-3 bg-black rounded-3"
                      type="text"
                      placeholder="Enter password"
                      name="password"
                      required={true}
                      value={data.password}
                    />
                    <button
                      type="button"
                      className="generate-btn btn btn-outline-success d-flex justify-content-center align-items-center"
                      style={{ width: "25%" }}
                      onClick={(e) => {
                        setPassword(generateRandomPassword(8));
                      }}
                    >
                      Generate
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-5" style={{ position: "relative" }}>
              <div style={{ border: "1px dashed black" }}></div>
              <div
                style={{
                  backgroundColor: "#18171b",
                  height: "2.5rem",
                  width: "2.5rem",
                  borderRadius: "100%",
                  position: "absolute",
                  left: "-28px",
                  top: "-19px",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "#18171b",
                  height: "2.5rem",
                  width: "2.5rem",
                  borderRadius: "100%",
                  position: "absolute",
                  right: "-28px",
                  top: "-19px",
                }}
              ></div>
            </div>
            <div
              style={{ width: "100%" }}
              className="mt-3 d-flex justify-content-center"
            >
              <div class="container mt-3 justify-content-center">
                <div class="row justify-content-center">
                  <div
                    style={{ width: "100%" }}
                    class="mt-3 d-flex justify-content-center"
                  >
                   <div class="vip pl-4">
                        <button
                          id="pay-btn"
                          type="button"
                          class="btn btn-sm "
                          name="addvip"
                          onClick={() => sendMailForManualAdding()}
                        >
                          ADD VIP
                        </button>
                      </div>

                    <div class="vip ms-2">
                      <button
                        id="pay-btn"
                        type="button"
                        class="btn btn-sm"
                        name="cancel"
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
      </ThemeProvider>
    </AddVipContainer>
  );
}
const AddVipContainer = styled.div`
 #image {
    object-fit: cover !important;
  }
 #otp-container {
  position: fixed; /* Fixed position to stay centered in the viewport */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #313131; /* Solid dark background */
  height: 20rem; /* Set a default height */
  width: 90%; /* Set a default width */
  max-width: 30rem; /* Restrict maximum width */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
  z-index: 1000; /* Ensure it stays on top of other elements */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Add some padding inside the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  #otp-container {
    width: 90%;
    height: auto; /* Adjust height to fit content */
    padding: 15px; /* Adjust padding */
  }

  .otp-input {
    width: 2.5rem; /* Adjust input width */
    height: 3rem; /* Adjust input height */
  }

  .verify-btn {
    font-size: 0.9rem; /* Adjust button text size */
    padding: 10px 20px; /* Adjust button padding */
  }
}

  .verify-btn {
    background-color: lightgrey;
    color: #1d1e22;
    border-radius: 1rem;
    padding: 1rem;
  }
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  #add-student-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  div,
  h6 {
    color: white;
  }
  .add-course-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
  .mini-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
  }
  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to individual boxes within containers */
  }
  .checkbox-container {
    display: flex; /* Display checkboxes horizontally */
    justify-content: flex-start; /* Align checkboxes to the start */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    margin-bottom: 20px; /* Add margin at the bottom */
  }
  .checkbox-container div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px; /* Add margin at the bottom of each checkbox */
  }

  .checkbox-container div:last-child {
    margin-right: 0; /* Remove margin for the last checkbox */
  }
  .pink-btn {
    border-color: rgb(201, 66, 149); /* Change the outline color to pink */
    color: rgb(201, 66, 149); /* Change text color to ensure visibility */
  }
  .generate-btn:hover {
    background-color: green;
  }
  .pink-btn:hover {
    background-color: #313131;
    color: rgb(255, 255, 255);
  }
  .btn-verify {
    background-color: green;
    color: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    cursor: pointer;
  }
    @media (max-width: 575px) {
    .add-course-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
`;
export default AddVipForm;
