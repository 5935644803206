import React, { useEffect, useState, useMemo } from "react";
import { useGlobalContext } from "../../assets/contex/GlobalContext";
import { URL as url } from "../../assets/mocData/config";
import axios from "axios";
import { styled } from "styled-components";
import DashBoardHeader from "../miniComponents/subcomponents/DashBoardHeader";
import DashBoardSecond from "../miniComponents/subcomponents/DashBoardSecond";
import DashBoardThird from "../miniComponents/subcomponents/DashBoardThird";
import DashBoardGraph from "../miniComponents/subcomponents/DashBoardGraph";
import { CircularProgress } from "@mui/material";

function DashboardContainer() {
  let { profileData,userRole } = useGlobalContext();
  const [dashboardDetail, setDashboardDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Memoize userRole to ensure it is stable across renders
  const stableUserRole = useMemo(() => userRole, [userRole]);

  const dashBoardDetails = async () => {
    setIsLoading(true);
    try {
      let response;
      const { role, access_token, profileData: profile } = profileData;

      if (role === "branchstaff") {
        response = await axios.get(
          `${url}/${role}/dashboard/?id=${profile.otherDetails.owner}&gmail=${profile.otherDetails.email}`,
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
      } else if (role === "admin" || role === "hos") {
        response = await axios.get(
          `${url}/admindash/dashboard/?id=${profile._id}&gmail=${profile.email}`,
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
      } else if (stableUserRole === "vipFranchise") {
        response = await axios.post(
          `${url}/admindash/dashboard/vipFranchise/?id=${profile._id}&gmail=${profile.email}`,
          { pinCodes: profile.otherDetails.pinCodes },
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
      } else if (stableUserRole === "vip" || stableUserRole === "franchise") {
        response = await axios.get(
          `${url}/${role}/dashboard/?id=${profile._id}&gmail=${profile.email}`,
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
      }

      // Update state if response exists
      if (response?.data) {
        setDashboardDetails(response.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Trigger API call when the userRole stabilizes
  useEffect(() => {
    if (stableUserRole) {
      dashBoardDetails();
    }
  }, [stableUserRole]); // Dependency array now has a stable variable


  if (!isLoading) {
    if (userRole == "vipFranchise"){
      return (
        <DashBoardContainer>
          <div id="dashboard">
            <DashBoardHeader wallet={dashboardDetail} />
            <DashBoardSecond dashboardDetail={dashboardDetail} />
            <DashBoardThird {...dashboardDetail} />
            {/* <DashBoardGraph user={profileData.role} {...dashboardDetail} /> */}
          </div>
        </DashBoardContainer>
      );
    }
      return (
        <DashBoardContainer>
          <div id="dashboard">
            {profileData.role == "admin" && (
              <>
                <DashBoardHeader wallet={false} />
                <DashBoardSecond dashboardDetail={dashboardDetail} />
                <DashBoardThird {...dashboardDetail} />
                {/* <DashBoardGraph user={profileData.role} {...dashboardDetail} /> */}
              </>
            )}
            {profileData.role == "hos" && (
              <>
                <DashBoardHeader wallet={false} />
                <DashBoardSecond dashboardDetail={dashboardDetail} />
                <DashBoardThird {...dashboardDetail} />
                {/* <DashBoardGraph user={profileData.role} {...dashboardDetail} /> */}
              </>
            )}
            {profileData.role == "vip" && (
              <>
                <DashBoardHeader wallet={dashboardDetail} />
                <DashBoardSecond dashboardDetail={dashboardDetail} />
                <DashBoardThird {...dashboardDetail} />
                {/* <DashBoardGraph user={profileData.role} {...dashboardDetail} /> */}
              </>
            )}
            {profileData.role == "branchstaff" && (
              <>
                <DashBoardHeader wallet={false} />
                <DashBoardSecond dashboardDetail={dashboardDetail} />
                <DashBoardThird {...dashboardDetail} />
                {/* <DashBoardGraph user={profileData.role} {...dashboardDetail} /> */}
              </>
            )}
            {profileData.role == "franchise" && (
              <>
                <DashBoardHeader wallet={false} />
                <DashBoardSecond dashboardDetail={dashboardDetail} />
                <DashBoardThird {...dashboardDetail} />
                {/* <DashBoardGraph user={profileData.role} {...dashboardDetail} /> */}
              </>
            )}
          </div>
        </DashBoardContainer>
      );
  } else {
    return (
      <DashBoardContainer
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <CircularProgress color="success" />
      </DashBoardContainer>
    );
  }
}

const DashBoardContainer = styled.div`
  width: 100%;
  .img-ctn-icon {
    height: 5rem;
    width: 5rem;
  }
  h1,
  h2 {
    color: var(--text-white);
  }
  .fa-solid {
    color: var(--icon-color);
    font-size: 1rem;
  }
  .ctn-inner {
    height: 3.5rem;
    width: 15rem;
    background-color: #36283a;
    border-radius: 10px;
  }

  h5 {
    color: var(--text-white);
    margin-top: "0.5rem";
  }
  #tg-11 {
    width: 30%;

    height: 70%;
    background-color: #18171b;
    border-radius: 10px;
    color: white;
  }
  #tg-12 {
    height: 3.5rem;
    width: 3.5rem;
  }
  #dashboard {
    width: 100%;
    padding: 1rem;
    height: 100vh;
    /* padding-left: 2.5rem;
    padding-right: 2.5rem; */
    overflow-y: scroll;
  }
  
  @media only screen and (max-width: 600px) {
    #dashboard {
      margin-left: 0;
    }
    .ctn-inner {
      margin-top:180%;
      margin-left:-230px;
    }
    .ctn-secondary {
      display: block !important;
    }
    #in-ctn {
      width: auto !important;
    }
  }

  .icon-btn {
    font-size: 2rem;
    padding: 0.2rem;
    /* color: #82407a; */
    color: #bf2f82c4;
  }

  .icon-btn {
    background-color: #36283a;
    border-radius: 5rem;
  }

  .selector {
    margin-left: 1.7rem;
    padding: 1rem;
    width: 30%;
    height: 3.5rem;
    background-color: #36283a;
    color: #bf2f82c4;
    text-align: center;
    border-radius: 10px;
  }

  .container-mini {
    cursor: pointer;
    display: flex;
    padding: 1rem;
    border-radius: 10px;
    background-color: #25272d;
    /* background-image: linear-gradient( to bottom,#25272d,#411d3d); */
    color: white;
    text-align: center;
    align-items: center;
    justify-content: space-around;
  }
  .content-inside span {
    font-family: Helvetica;
    font-size: small;
  }
  .content-inside h2 {
    font-family: Helvetica;
  }

  .graph-container {
    background-color: #25272d;
    /* background-image: linear-gradient( to bottom,#25272d,#4b304b); */
    color: white;
    padding: 1rem;
    border-radius: 10px;
  }

  .wallet-container {
    background-color: #25272d;
    height: 10rem;
    width: 100%;
    background-image: linear-gradient(to top, #ec008a4c, #25272d);
    display: flex;
    color: white;
    align-items: center;
    border-radius: 10px;
    position: relative;
    padding: 2rem;
  }
  .wallet-container span {
    font-size: 12rem;
    color: #18171b;
    position: absolute;
    right: 1rem;
  }
  .wallet-container .wallent-content {
    position: absolute;
    z-index: 2;
  }

  .simple-icon {
    font-size: 3rem;
    padding-right: 1rem;
    color: #25272d;
  }

  .mini-cont {
    display: flex;
    background-color: #18171b;
    align-items: center;
    text-align: center;
    margin: 0.5rem;
    border-radius: 10px;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .container {
    max-width: 100% !important;
  }
`;

export default DashboardContainer;
