import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { enqueueSnackbar } from "notistack";
import { deleteData, fetchData, imageURL } from "../../../Api";
import { URL as url } from "../../../assets/mocData/config";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import EditHosForm from "../EditForms/EditHosForm";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Grid } from "@mui/material";
import Cards from "../subcomponents/Cards";
import { Icon } from "react-icons-kit";
import { ic_search } from "react-icons-kit/md/ic_search";
import icon from "../../../assets/images/Tia - font.png";

function ViewHoStaff({ filterData }) {
  const [hosData, setHosData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editHosContainer, setEditHosContainer] = useState(false);
  const [editHosData, setEditHosData] = useState({});
  const { userRole, userToken } = useGlobalContext();

  // Fetch HOS data
  useEffect(() => {
    const fetchHos = async () => {
      let headers = {
        userRole: userRole,
        userToken: userToken,
      };
      try {
        let response = await fetchData(`${url}/admin/hos`, headers);
        if (response) {
          setHosData(response.data);
          setFilteredData(response.data); // Initialize filtered data
        }
      } catch (error) {
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
          autoHideDuration: 1000,
        });
      }
    };

    fetchHos();
  }, [userRole, userToken]);

  // Filter HOS data based on search term (filterData)
  useEffect(() => {
    if (!filterData) {
      setFilteredData(hosData); // If no search term, show all data
    } else {
      const lowercasedFilter = filterData.toLowerCase();
      const filteredArray = hosData.filter((data) =>
        data.user_id?.toLowerCase().includes(lowercasedFilter) ||
        data.userName?.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredData(filteredArray);
    }
  }, [filterData, hosData]);

  return (
    <ViewHoStaffContainer>
      {editHosContainer ? (
        <EditHosForm
          setEditHosData={setEditHosData}
          editHosData={editHosData}
          setEditHosContainer={setEditHosContainer}
        />
      ) : (
        <div
          style={{
            height: "80vh",
            overflowY: "scroll",
            paddingTop: "1rem",
          }}
        >
          {filteredData.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                paddingTop: "4rem",
              }}
            >
              <SentimentVeryDissatisfiedIcon />
              <p>No Records Found</p>
            </div>
          ) : (
            <Grid container spacing={2}>
              {filteredData.map((data) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={data.user_id}>
                  <Cards
                    id={data.user_id}
                    image={data.imageACCESSKEY}
                    
                    name={data.userName}
                    status={data.status}
                    data={data}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      )}
    </ViewHoStaffContainer>
  );
}




const ViewHoStaffContainer = styled.div`
  color: white;
  height: 83vh;
  width: 95%;
  margin-top: 2rem;
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }

  .accordion-item {
    background-color: var(--navbar-dark-primary);
  }
  .accordion-button {
    color: var(--icon-color) !important;
  }
  h6 {
    color: white;
    text-align: center;
    font-weight: 900;
  }

  .fa-solid {
    font-size: 1rem;
    color: var(--icon-color);
  }
  .text {
    margin-left: 0.6rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: white;
  }
  .nav-user-img {
    display: flex;

    justify-content: center;
    height: 7rem;
    overflow: hidden;
    color: white;
  }
  #img-sec {
    position: relative;
    height: 100%;
    width: 7rem;
    overflow: hidden;
    border-radius: 100%;
    cursor: pointer;
  }
  #img-sec img {
    position: absolute;
    object-fit: contain;
  }
  #img-sec span {
    position: absolute;
    color: white;
    bottom: -24px;
    font-size: 0.6rem;
    background-color: orange;
    width: 100%;
    height: 3rem;
    text-align: center;
    border-bottom-left-radius: 80%;
  }

  .btn {
    background-color: #1d1e22;
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }

  .container-box1 {
    border: 1px solid grey; /* Change color as needed */
    padding: 5px 25px 1px 20px; /* Adjust padding as needed */
    display: inline-block; /* Ensure the box takes only the required width */
    border-radius: 5px; /* Add some rounded corners */
  }

  .container-box3 {
    height: 35px;
    width: 135px;
    border: 1px solid #ffffff; /* Border style */
    border-radius: 0px; /* Border radius */
    margin: 20px; /* Add margin as needed */
    position: relative; /* Set position to relative for absolute positioning of the icon */
    right: 0px;

    position: relative;
    color: white;
    padding: 5px 10px 25px 25px; /* Adjust padding to move text within button */
  }

  .head {
    margin-left: 5px;
    margin-bottom: 30px;
  }

  .btn-group {
    top: -50px;
    left: 60px;
  }

  .icon {
    position: relative;
    left: 853px;
    top: -58px;
    color: #ec008bde;
    font-size: 24px; /* Adjust the font size as needed */
  }
  .icon1 {
    position: relative;
    left: 705px;
    top: -58px;
    color: white;
    font-size: 24px; /* Adjust the font size as needed */
  }
  .icon2 {
    position: relative;
    left: 740px;
    top: -90px;
    color: blue;
    font-size: 24px; /* Adjust the font size as needed */
  }
  .icon3 {
    position: relative;
    left: 600px;
    top: -90px;
  }
  .icon4 {
    position: relative;
    left: 490px;
    top: -132px;
  }
  .icon5 {
    position: relative;
    left: 275px;
    top: -173px;
  }

  .btn-outline-info-edit {
    background-color: #007bff;
    padding: 4px 28px;
    margin: 6px;
    border-radius: 5px; /* Or adjust as needed */
    text-align: center;
    color: white;
    font-size: 12px;
  }

  .btn-outline-danger-delete {
    background-color: red;
    padding: 4px 28px;
    margin: 6px;
    border-radius: 5px; /* Or adjust as needed */
    text-align: center;
    color: white;
    font-size: 12px;
  }
  .btn-outline-success-active {
    padding: 4px 58px;
    margin: 6px;
    border-radius: 5px; /* Or adjust as needed */
    text-align: center;
    color: white;
    font-size: 12px;
    background-color: green;
  }

  .container-main {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    padding-left: -10px;
    margin-right: -115px;
  }
  .container-main > div {
    margin-right: 80px;
    margin-bottom: -10px;
    margin-left: -10px;
    margin-top: -20px;
  }
  .cont {
    flex: 0 0 100%;
    margin-bottom: 20px;
    color: #ec008bde;
    font-size: 14px;
  }
  .cont1 {
    flex: 0 0 100%;
    margin-bottom: 20px;
    font-size: 18px;
    color: white;
  }

  .container6 {
    position: relative; /* Set position to relative for absolute positioning of the icon */
    color: white;
    border-radius: 10px;
    background-color: #1d1e22;
    height: 370px; /* Reduce the height */
    top: 0; /* Set top to 0 */
    left: 10px;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px; /* Add padding for better spacing */

    margin-bottom: 20px;
  }

  .container6 .row {
    display: flex;
    flex-wrap: wrap;
  }

  .container6 .col-md-6,
  .container6 .col-lg-4 {
    padding: 10px;
    box-sizing: border-box;
  }

  .info-boxa {
    position: relative;
    white-space: nowrap;
    margin-bottom: 20px; /* Adjust bottom margin as needed */
    margin-left: 3 0px;
  }

  .info-itema {
    display: inline-block; /* Display as inline-block elements */
    vertical-align: top; /* Align items to the top */
    margin-right: 130px; /* Add some space between each item */
    margin-bottom: 5px; /* Reduce the bottom margin */
    margin-left: 8px;
  }

  .label {
    color: #ec008bde;
  }

  .info-itema p {
    font-size: 16px;
  }

  .info-itema .value {
    font-size: 20px;
  }

  .container7 {
    position: relative; /* Set position to relative for absolute positioning of the icon */
    color: white;
    border-radius: 10px;
    background-color: #1d1e22;
    width: 1000px;
    height: 240px;
    bottom: 30px;
    left: 40px;
    padding: 20px;
  }

  .container7 .row {
    display: flex;
    flex-wrap: wrap;
  }

  .container7 .col-md-6,
  .container7 .col-lg-4 {
    padding: 10px;
    box-sizing: border-box;
  }

  .info-box {
    position: relative;
    white-space: nowrap;
    margin-bottom: 10px;
    margin-left: 50px;
  }

  .info-item {
    display: inline-block; /* Display as inline-block elements */
    vertical-align: top; /* Align items to the top */
    margin-right: 130px; /* Add some space between each item */
  }

  .info-label {
    color: #ec008bde;
  }
  .info-item p {
    font-size: 16px;
  }

  .info-item .info-value {
    font-size: 22px;
  }

  .container8 {
    position: relative; /* Set position to relative for absolute positioning of the icon */
    color: white;
    border-radius: 10px;
    background-color: #1d1e22;
    width: 1000px;
    height: 230px;
    bottom: 30px;
    left: 40px;
    padding: 20px;
  }

  .container8 .row {
    display: flex;
    flex-wrap: wrap;
  }

  .container8 .col-md-12,
  .container8 .col-lg-12 {
    padding: 10px;
    box-sizing: border-box;
  }

  .info-boxy {
    position: relative;
    white-space: nowrap;
    margin-bottom: 20px;
    margin-left: 30px;
  }

  .info-itemy {
    display: inline-block;
    vertical-align: top;
    margin-right: 130px;
  }

  .info-itemy:last-child {
    margin-right: 0;
  }

  .info-itemy p {
    font-size: 16px;
  }

  .info-itemy .info-value {
    font-size: 22px;
  }
  .info-labely {
    color: #ec008bde;
  }

  .container9 {
    position: relative;
    border-radius: 10px;
    background-color: #1d1e22;
    width: 90%;
    padding: 20px;
    min-height: 480px; /* Set minimum height */
    margin-left: 30px;
    margin-bottom: 30px;
  }

  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    padding-right: 20px;
    margin-bottom: 20px;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    width: 100%; /* Set width to 100% */
  }

  .checkbox-container label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%; /* Set width to 100% */
  }

  .checkbox-container label:last-child {
    margin-right: 0;
  }

  .checkbox-container label input {
    display: none;
  }

  .pink-btn {
    border-color: rgb(201, 66, 149);
    color: rgb(201, 66, 149);
  }

  .password-box {
    margin-top: 20px;
    width: 100%;
  }

  .password-field {
    width: calc(100% - 30px);
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    background-color: black;
    color: white;
  }

  .toggle-button-wrapper {
    position: absolute;
    top: 50%;
    left: calc(100% - 30px);
    transform: translateY(-50%);
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .toggle-password {
    font-size: 12px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #007bff;
  }

  .section-title {
    font-size: 18px;
    color: white;
  }

  /* Media query for larger screens */
  @media (min-width: 769px) {
    .checkbox-container .btn {
      flex: 0 0 calc(33.53% - 50px); /* Adjust button width for larger screens */
    }
    .password-field {
      width: calc(
        25% - 20px
      ); /* Adjust width of password field for larger screens */
    }
    .toggle-button-wrapper {
      left: calc(
        30% + -10px
      ); /* Adjust position of toggle button for larger screens */
      top: 90%; /* Align toggle button vertically */
      transform: translateY(-50%); /* Center toggle button vertically */
    }
  }

  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .container9 {
      padding: 10px;
      margin-right: 20px;
    }

    .checkbox-container .btn {
      flex: 0 0 calc(50% - 10px);
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .password-box {
      margin-top: 20px;
    }

    .toggle-button-wrapper {
      left: calc(100% - 30px);
    }
    .password-field {
      width: calc(
        60% - 20px
      ); /* Adjust width of password field for smaller screens */
    }

    .toggle-button-wrapper {
      left: calc(
        55% + 15px
      ); /* Adjust horizontal position of toggle button for smaller screens */
      top: 95%; /* Align toggle button vertically */
      transform: translateY(-50%); /* Center toggle button vertically */
    }
  }

  /* Base styles */

  /* Add your base styles here */

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    /* Adjustments for smaller devices */

    #img-sec {
      height: 40%;
      width: 5rem;
      overflow: hidden;
      border-radius: 100%;
      cursor: pointer;
    }

    .container-box3 {
      height: 10px;
      width: 50px;
      border: 1px solid #ffffff; /* Border style */
      border-radius: 0px; /* Border radius */
      margin: 0px; /* Add margin as needed */
      top: -60px;
      right: 0px;
      padding: 0px;

      color: white;
      padding: 5px 5px 15px 10px; /* Adjust padding to move text within button */
    }
    .head {
      font-size: 6px;
    }

    .container-main1,
    .container-main2,
    .container-main3,
    .container-main4 {
      left: 0;
    }

    .cont,
    .cont1 {
      flex: 0 0 calc(50% - 20px);
    }

    .icon {
      top: -15px;
      left: 185px;
      transform: none;
      margin-top: 10px;
      margin-left: 110;
      font-size: 10px; /* Adjust the font size as needed */
    }
    .icon1 {
      top: -41px;
      left: 160px;
      transform: none;
      margin-top: 10px;
      margin-left: 110;
      font-size: 10px; /* Adjust the font size as needed */
    }
    .icon2 {
      top: -66px;
      left: 133px;
      transform: none;
      margin-top: 10px;
      margin-left: 110;
      font-size: 10px; /* Adjust the font size as needed */
    }
    .icon3 {
      top: -87px;
      left: 91px;
      transform: none;
      margin-top: 10px;
      margin-left: 10;
      font-size: 5px; /* Adjust the font size as needed */
    }
    .icon4 {
      top: -107px;
      left: 55px;
      transform: none;
      margin-top: 10px;
      margin-left: 10;
      font-size: 5px; /* Adjust the font size as needed */
    }
    .icon5 {
      top: -130px;
      left: 20px;
      transform: none;
      margin-top: 10px;
      margin-left: 10;
      font-size: 5px; /* Adjust the font size as needed */
    }

    .btn-outline-info-edit {
      padding: 2px 5px;
      border-radius: 1rem; /* Or adjust as needed */
      text-align: center;
      color: white;
      font-size: 5px;
    }

    .btn-outline-danger-delete {
      background-color: red;
      padding: 2px 5px;
      margin: 2px;
      border-radius: 1rem; /* Or adjust as needed */
      text-align: center;
      color: white;
      font-size: 5px;
    }
    .btn-outline-success-active {
      padding: 2px 5px;
      margin: 2px;
      border-radius: 1rem; /* Or adjust as needed */
      text-align: center;
      color: white;
      font-size: 5px;
    }

    .container6 {
      width: 90%;
      height: auto;
      max-width: none;
      margin: 20px auto;
      left: 0;
    }

    .container7,
    .container8 {
      width: 90%;
      height: auto;
      max-width: none;
      margin: 20px auto;
      left: 0;
    }

    .info-box,
    .info-boxa,
    .info-boxy {
      margin-left: 0;
    }

    .info-item,
    .info-itema,
    .info-itemy {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;
    }

    .info-item:last-child {
      margin-bottom: 0;
    }
  }
`;
export default ViewHoStaff;
