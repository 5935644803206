import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import { Pagination, Stack,Button } from "@mui/material";
import * as XLSX from 'xlsx';

function PendingStudent({ filterData, setStudentData, setSelectedView }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items per page
  const [userData, setUserData] = useState([]);
  
  const { userToken, userRole, profileData } = useGlobalContext();
  const navigate = useNavigate();

  // Ensure userData is always an array before filtering
  const filteredData = (userData || []).filter((user) => {
    try {
      return (
        (user.personalInfo?.studentName?.toLowerCase() || "").includes(filterData.toLowerCase()) ||
        (user.referenceName?.toLowerCase() || "").includes(filterData.toLowerCase()) ||
        (user.courseDetail?.branchName?.branchName?.toLowerCase() || "").includes(filterData.toLowerCase()) ||
        (user.courseDetail?.courseName?.courseName?.toLowerCase() || "").includes(filterData.toLowerCase()) ||
        (user.familyDetails?.fathersName?.toLowerCase() || "").includes(filterData.toLowerCase()) ||
        (user.familyDetails?.fathersPhone?.toString() || "").includes(filterData) ||
        (user.familyDetails?.mothersName?.toLowerCase() || "").includes(filterData.toLowerCase()) ||
        (user.familyDetails?.mothersPhone || "").includes(filterData)
      );
    } catch (error) {
      console.error("Error while filtering user data:", error);
      return false; // Exclude this user from the filtered list in case of error
    }
  });
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const fetchFranchise = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response;
      if (userRole === "admin" || userRole === "hos" ) {
        response = await fetchData(`${url}/students/admin/pending`, header);
      } else if (userRole === "vip") {
        response = await fetchData(`${url}/students/pending/${profileData.profileData._id}`, header);
      } else if (userRole === "franchise") {
        response = await fetchData(`${url}/students/pending/franchise/${profileData.profileData.email}`, header);
      } else if (userRole === "branchstaff") {
        response = await fetchData(`${url}/students/pending/franchise/${profileData.profileData.otherDetails.email}`, header);
      }

      // Ensure response is an array
      setUserData(Array.isArray(response) ? response : []);
      
    } catch (error) {
      enqueueSnackbar(`${error.message || error}`, { variant: "error" });
      setUserData([]); // Reset to empty array on error
    }
  };

  useEffect(() => {
    fetchFranchise();
  }, []);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(currentData.map((user, i) => ({
      'S.no': indexOfFirstItem + i + 1,
      'Name': user.personalInfo.studentName,
      'Age': user.personalInfo.age,
      'Referred By': user.referenceName,
      'Referred To':user.courseDetail.branchName.branchName,
      'Course Name': user.courseDetail.courseName.courseName,
      'Father Name': user.familyDetails.fathersName,
      'Father Phone Number': user.familyDetails.fathersPhone,
      'Father Whatsapp Number': user.familyDetails.fathersWhatsAppNumber,
      'Mother Name': user.familyDetails.mothersName,
      'Mother Phone Number': user.familyDetails.mothersPhone,
      'Mother Whatsapp Number': user.familyDetails.mothersWhatsAppNumber,
      'Status': user.status,
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pending Students');
    XLSX.writeFile(workbook, 'PendingStudents.xlsx');
  };

  const handleAddToLeads = (user) => {
    // Navigate to the CRM page and send the student data
    navigate('/crmlead', { state: { studentData: user } });
  };
  return (
    <Container>
      <div id="viewFranchise-table-container">
        {userRole=="admin" &&(
      <Button
            variant="outlined"
             className="download-btn"
            onClick={exportToExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
              color:"#f00d88",
              borderColor:"#f00d88",
             // Customize color as needed
              
            }}
            disabled={currentData.length===0}
          >
            Download Excel
          </Button>)}
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">S No</th>
                  <th className="t-head" scope="col">Name</th>
                  <th className="t-head" scope="col">Age</th>
                  <th className="t-head" scope="col">Referred By</th>
                  {(userRole === "admin") && (
                    <th className="t-head" scope="col">Referred To</th>
                  )}
                  <th className="t-head" scope="col">Course Name</th>
                  <th className="t-head" scope="col">Father Name</th>
                  <th className="t-head" scope="col">Father Phone Number</th>
                  <th className="t-head" scope="col">Father Whatsapp Number</th>
                  <th className="t-head" scope="col">Mother Name</th>
                  <th className="t-head" scope="col">Mother Phone Number</th>
                  <th className="t-head" scope="col">Mother Whatsapp Number</th>
                  <th className="t-head" scope="col">Status</th>
                  {(userRole === "franchise" || userRole === "branchstaff") && (
                    <th className="t-head" scope="col">Action</th>
                  )}
                </tr>
              </thead>
              <tbody className="table-dark">
                {currentData.map((user, i) => (
                  <tr key={i}>
                    <td>{indexOfFirstItem + i + 1}</td>
                    <td>{user.personalInfo.studentName}</td>
                    <td>{user.personalInfo.age}</td>
                    <td>{user.referenceName}</td>
                    {(userRole === "admin") && (
                      <td>{user.courseDetail.branchName.branchName}</td>
                    )}
                    <td>{user.courseDetail.courseName.courseName}</td>
                    <td>{user.familyDetails.fathersName}</td>
                    <td>{user.familyDetails.fathersPhone}</td>
                    <td>{user.familyDetails.fathersWhatsAppNumber}</td>
                    <td>{user.familyDetails.mothersName}</td>
                    <td>{user.familyDetails.mothersPhone}</td>
                    <td>{user.familyDetails.mothersWhatsAppNumber}</td>
                    <td>
                      <span className="badge" style={{
                        height: "80%", width: "100%", color: '#F6C324',
                        backgroundColor: 'transparent',
                        border: `1px solid #F6C324`,
                        padding: "0.6rem", textAlign: 'center'
                      }}>
                        {user.status}
                      </span>
                    </td>
                    {(userRole === "franchise" || userRole === "branchstaff") && (
                      <td>
                        <span
                          className="badge"
                          style={{
                            height: "80%", width: "100%",
                            color: "white", border: "1px solid white",
                            padding: "0.6rem", textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setStudentData({ ...user });
                            setSelectedView("ReferralsStudent");
                          }}
                        >
                          Add Student
                        </span>
                        {(userRole === "franchise") && (
                        <span
                          className="badge"
                          style={{
                            marginTop:"1rem",
                            height: "80%", width: "100%",
                            color: "white", border: "1px solid white",
                            padding: "0.6rem", textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleAddToLeads(user)}
                        >
                          Add To Leads
                        </span>
                      )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(filteredData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </Container>
  );
}

const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;

const Container = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  #viewFranchise-table-container {
    width: 100%;
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }
  td {
    text-align: center;
  }
  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }
  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }
  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;

export default PendingStudent;
