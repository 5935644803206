import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import logo from '../../../assets/images/TiaR.jpg';
import { fetchData, putData } from "../../../Api";
import moment from 'moment';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'; 
import {
  Button,
  ButtonGroup,
  Rating,
  TextField,
  Typography,
  Stack,
  Pagination,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import axios from "axios";
import AWS from "aws-sdk";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import certificareImage from "../../../assets/images/branch.png";
import contentimg from "../../../assets/images/download.jpg";
import { format } from "date-fns";
import DownloadingIcon from '@mui/icons-material/Downloading';
import * as XLSX from "xlsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Studentadmission({ filterData }) {
  const Paginations = styled(Pagination)`
    color: white; // Set text color to white
    & .MuiPaginationItem-root {
      color: white; // Ensure pagination items have white text
    }
    & .MuiPaginationItem-root.Mui-selected {
      background-color: #333; // Optionally, set background for selected item
      color: white; // Ensure selected pagination item has white text
    }
  `;

  const StudentadmissionContainer = styled.div`
    .download-btn {
      margin-top: 1rem;
    }
/* Button group for better alignment */
.button-group {
  display: flex;
  gap:2px; /* Adjusted gap for a cleaner look */
}

/* Base button styles */
.btn {
  padding:0.6rem;
  font-size: 12px;
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: 2px solid #fff;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

/* Hover effect for all buttons */
.btn:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #fff;
}

/* Discontinued button styles */
.outlined-error {
  color: #f44336;
  border-color: #f44336;
}

.selected-discontinued {
  background-color: #f44336;
  color: white;
  border-color: #f44336;
}

/* Studying button styles */
.outlined-studying {
  color: orange;
  border-color: orange;
}

.selected-studying {
  background-color: orange;
  color: white;
  border-color: orange;
}

/* Completed button styles */
.outlined-success {
  color: #4caf50;
  border-color: #4caf50;
}

.selected-completed {
  background-color: #4caf50;
  color: white;
  border-color: #4caf50;
}





    @media (max-width: 1024px) {
      .download-btn {
        display: none;
      }
    }
    height: auto;
    background-color: var(--background);
    .empty {
      height: 80%;
      background-color: #25272d;
      border-radius: 20px;
    }
    h1 {
      color: white;
    }

    .dot {
      height: 15px;
      width: 15px;
      background-color: #219621;
      border-radius: 50%;
      display: inline-block;
    }
    .dot-red {
      height: 15px;
      width: 15px;
      background-color: #c82323;
      border-radius: 50%;
      display: inline-block;
    }
    td {
      text-align: center;
    }
    .fa-solid {
      font-size: 1.3rem;
      color: var(--icon-color);
    }
    h4 {
      margin-left: 1em;
    }
    @media (max-width: 768px) {
      .col-9 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      #profile-container {
        margin-top: 1rem;
      }
    }
    width: 100%;
    #course-list {
      width: 100%;
      padding: 2rem;
    }

    ::placeholder {
      color: gray;
      opacity: 1; /* Firefox */
    }
    ::-ms-input-placeholder {
      /* Edge 12-18 */
      color: gray;
    }
    
    #line {
      background-color: var(--icon-color);
      height: 3px;
      width: 100%;
    }
    h2 {
      color: white;
    }

    #searchfield {
      height: 3.5rem;
      padding-right: 25px;
      background-color: #36283a;
      padding: 1rem;
      background-size: 20px;
      border-radius: 5px;
      margin-right: 1rem;
    }

    #searchfield > input {
      border: 0px solid;
      background-color: #36283a;
      height: 20px;
      width: 10rem;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
    ::placeholder {
      color: #bf2f82c4;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #bf2f82c4;
    }

    .font {
      border: 0px solid;
      color: #bf2f82c4;
    }

    .icon-button {
      font-size: 2rem;
      padding: 0.2rem;
      background-color: #36283a;
      border-radius: 5rem;
      width: 3.5rem;
    }

    .icon {
      color: #bf2f82c4;
      border-radius: 5rem;
    }

    #table-container {
      background-color: #25272d;
      margin-top: 1rem;
      padding: 2rem;
      border-radius: 10px;
      height: 70vh;
      width: 100%;
    }

    #table-container thead th {
      position: sticky;
      top: 0;
    }

    .t-head {
      background-color: #18171b !important;
      color: white !important;
      padding: 1rem !important;
      text-align: center;
    }
    h4 {
      text-align: center;
    }

    table.rounded-corners {
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 10px;
    }

    table.rounded-corners th,
    table.rounded-corners td {
      border: 1px solid black;
    }

    thead tr {
      border-bottom: 1rem solid #25272d;
    }
    tr {
      border-color: #25272d;
    }
    .table {
      background-color: var(--navbar-dark-primary);
    }
    .table > :not(caption) > * > * {
      background-color: #25272d;
    }
    /* Style for active radio button */
  `;
  const CenterGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%; /* Ensure the group takes full width */
`;

const Select = styled.select`
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #36283a;
  color: white;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
    margin: 0 3px;
  }
`;

  let [userData, setUserData] = useState([]);
  const [open, setOpen] = React.useState(false);
  let [user, setUser] = useState({});
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userToken, userRole, profileData, setProfileViewData } =
    useGlobalContext();

  const handleClickOpen = (user) => {
    if (!user || !user.otherDetails) {
      enqueueSnackbar("User data is missing, unable to process payment.", {
        variant: "error",
      });
      return;
    }
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const fetchFranchise = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response;
      if (userRole === "admin" || userRole === "hos") {
        response = await fetchData(`${url}/students/admin/studying`, header);
      } else if (userRole == "branchstaff") {
        response = await fetchData(
          `${url}/students/studying/franchise/${profileData.profileData.otherDetails.email}`,
          header
        );
      } else {
        response = await fetchData(
          `${url}/students/studying/franchise/${profileData.profileData.email}`,
          header
        );
      }

      console.log(response);
      setUserData(response);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };

  const paymentPending = (totalPayment, paymentPending) => {
    return parseInt(totalPayment) - parseInt(paymentPending);
  };
  const payAmount = async (amount) => {
    // Check if user and user.otherDetails are defined
    if (
      !user ||
      !user.otherDetails ||
      typeof user.otherDetails.amountToPay === "undefined"
    ) {
      enqueueSnackbar("User details or payment information is missing.", {
        variant: "error",
      });
      return;
    }
  
    const pendingAmount =
      user.otherDetails.amountToPay - user.otherDetails.amountPaid;
  
    // Check if payment amount is zero
    if (parseInt(amount) === 0) {
      enqueueSnackbar("Please enter a valid payment amount.", {
        variant: "error",
      });
      return;
    }
  
    // If payment is positive, ensure it doesn't exceed the pending amount
    if (parseInt(amount) > 0 && parseInt(amount) > pendingAmount) {
      enqueueSnackbar(
        `Amount exceeds the pending payment of ₹${pendingAmount}`,
        { variant: "error" }
      );
      return;
    }
  
    // If payment is negative, ensure it doesn't exceed the amount already paid
    if (
      parseInt(amount) < 0 &&
      Math.abs(parseInt(amount)) > user.otherDetails.amountPaid
    ) {
      enqueueSnackbar(
        `Negative amount exceeds the total amount already paid of ₹${user.otherDetails.amountPaid}`,
        { variant: "error" }
      );
      return;
    }
  
    try {
      let statusT = "pending";
  
      // Update the status if total amount is fully paid
      if (
        user.otherDetails.amountToPay -
          (user.otherDetails.amountPaid + parseInt(amount)) <=
        0
      ) {
        statusT = "paid";
      }
  
      // Make the payment update request
      let response = await axios.put(
        `${url}/accounts/students/${user._id}/payment`,
        { amountToAdd: amount, status: statusT },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            role: userRole,
          },
        }
      );
  
      console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  
  const amountStatus = async (user, status) => {
    console.log(user, status);
    try {
      let response = await axios.put(
        `${url}/accounts/students/${user._id}/status`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            role: userRole,
          },
        }
      );
      console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFranchise();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Adjust as needed

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getTotalPages = (data) => {
    return Math.ceil(data.length / itemsPerPage);
  };

  const [filterMonth, setFilterMonth] = useState("All");
  const [filterYear, setFilterYear] = useState("All");

  const handleFilterMonth = (event) => {
    setFilterMonth(event.target.value);
  };

  const handleFilterYear = (event) => {
    setFilterYear(event.target.value);
  };
  // Filter userData based on filterData prop (search)
  const filteredData = userData.filter((user) => {
    const studentName = user.personalInfo.studentName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const parentNumber1 = user.familyDetails.fathersPhone
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const parentNumber2 = user.familyDetails.mothersPhone
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const courseName = user.courseDetail.courseName.courseName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const conceptName = user.courseDetail.conceptName.conceptName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const email = user.courseDetail.branchName.branchOwnerEmail
      .toLowerCase()
      .includes(filterData.toLowerCase());

      const formattedDate = new Date(user.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }).toLowerCase();
      const dateMatch = formattedDate.includes(filterData.toLowerCase());
  
      const userMonth = moment(user.createdAt).format("MM");
      const userYear = moment(user.createdAt).format("YYYY");
  
      const isMonthMatch = filterMonth === "All" || filterMonth === userMonth;
      const isYearMatch = filterYear === "All" || filterYear === userYear;
  
      return (
        (studentName ||
          parentNumber1 ||
          parentNumber2 ||
          courseName ||
          conceptName ||
          email ||
          dateMatch) &&
        isMonthMatch &&
        isYearMatch
      );
    });
    const paginatedData = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  
    const currentYear = parseInt(moment().format("YYYY"));
    const years = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => 2024 + i);
  
  

  
  if (viewStats) {
    return (
      <Viewstats
        viewStats={viewStats}
        setViewStatsContainer={setViewStatsContainer}
      />
    );
  }
  const handleDownload = () => {
    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((user) => ({
        "S.no": filteredData.indexOf(user) + 1,
        "Student Name": user.personalInfo.studentName,
        "Phone Number": user.personalInfo.phoneNumber,
        "Age": user.personalInfo.age,
        "Date of Birth": user.personalInfo.dob,
        "Blood Group": user.personalInfo.bloodGroup,
        "Class": user.personalInfo.classCourse,
        "School/College": user.personalInfo.schoolCollege,
        "Gender": user.personalInfo.gender,
        "Father's Name": user.familyDetails.fathersName,
        "Father's Phone": user.familyDetails.fathersPhone,
        "Father's WhatsApp": user.familyDetails.fathersWhatsAppNumber,
        "Mother's Name": user.familyDetails.mothersName,
        "Mother's Phone": user.familyDetails.mothersPhone,
        "Mother's WhatsApp": user.familyDetails.mothersWhatsAppNumber,
        "Course Name": user.courseDetail.courseName.courseName,
        "Concept Name": user.courseDetail.conceptName.conceptName,
        "Course Fee + GST": `${user.courseDetail.courseName.courseFee} + ${user.courseDetail.courseName.gst}% GST`,
        "Amount to Pay": user.otherDetails.amountToPay,
        "Amount Paid": user.otherDetails.amountPaid,
        "Amount Pending": user.otherDetails.amountToPay - user.otherDetails.amountPaid,
        "Payment Status": user.otherDetails.status,
        "Student Status": user.status,
        "Address": `${user.address.flatNo}, ${user.address.street}, ${user.address.district}, ${user.address.state}, ${user.address.pinCode}, ${user.address.country}`,
        // Formatting the transactions as a string
        "Transactions": user.transactions.map(transaction => 
          `Date: ${new Date(transaction.date.$date).toLocaleDateString()} | Amount: ${transaction.amount.$numberInt}`
        ).join('; '),
        "Feedback": user.feedBackquestion ? `Feedback 1: ${user.feedBackquestion.feedback1}, Feedback 2: ${user.feedBackquestion.feedback2}, Feedback 3: ${user.feedBackquestion.feedback3}, Feedback 4: ${user.feedBackquestion.feedback4}, Feedback 5: ${user.feedBackquestion.feedback5}` : 'No feedback',
      }))
    );
  
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
  
    // Export the workbook
    XLSX.writeFile(workbook, "StudentAdmissionData.xlsx");
  };
  
  const downloadReceipt = (user) => {
    const doc = new jsPDF();
    const hasTransactions = user?.transactions?.some(transaction => transaction.amount > 0);
  
    if (!hasTransactions) {
      enqueueSnackbar('No payment details available.', { variant: 'warning' });
      return;
    }
  
    const pageHeight = doc.internal.pageSize.height; // Get the page height
    const lineHeight = 10; // Approximate line height
    let currentY = 20; // Start Y position
  
    // Function to add a new page
    const addPage = () => {
      doc.addPage();
      currentY = 20; // Reset Y position for new page
    };
  
    // Function to check if a new page is needed
    const checkPageHeight = (additionalHeight = 0) => {
      if (currentY + additionalHeight > pageHeight) {
        addPage();
      }
    };
  
    // Function to add a colored rectangle
    const addColoredRectangle = (x, y, width, height, color) => {
      doc.setFillColor(color);
      doc.rect(x, y, width, height, 'F');
    };
  
    // Company Info Section
    checkPageHeight(30); // Check height for company info
  ; // Light gray background
    
 // Add company name with logo
const logoWidth = 35; // Width of the logo
const logoHeight = 35; // Height of the logo

// Add logo to the right side
doc.addImage(logo, 'PNG', 20, 10, logoWidth, logoHeight); // Adjust Y position to center vertically with text

// Make the door number bold and larger
doc.setFontSize(25); // Larger size
doc.setFont('helvetica', 'bold'); // Set to bold
doc.setTextColor(240, 13, 136); // Set text color to pink (RGB for pink)

doc.text(` ${profileData.profileData.doorFlatNumber}`, 75, currentY);

currentY += lineHeight;
doc.setTextColor(0,0,0); 
// Reset font size and style for the remaining text
doc.setFontSize(8);
doc.setFont('helvetica', 'normal'); // Back to normal font
doc.text(
  ` ${profileData.profileData.streetName} ${profileData.profileData.district} ${profileData.profileData.state} ${profileData.profileData.country} ${profileData.profileData.pinCode}`,
  75,
  currentY
);
currentY += lineHeight;

doc.text(
  `Email:${user.courseDetail.branchName.branchOwnerEmail}, Website:www.tiaedu.in`,
  76,
  currentY
);
currentY += lineHeight;

   // Generate two random 3-digit numbers
    const firstPart = Math.floor(1000 + Math.random() * 900);
      const secondPart = Math.floor(10000 + Math.random() * 900);

    // Add Invoice number with a slash between the two parts
    doc.text(`No.: ${firstPart}/${secondPart}`, 150, currentY+20 );
    currentY += lineHeight;

    const date = new Date();
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    doc.text(`Date: ${formattedDate}`, 150, currentY+20);
    currentY += lineHeight;
  
    // Student and Course Info
    checkPageHeight(30); // Check height for student info
    doc.setFontSize(8);
    doc.text(`Name: ${user?.personalInfo?.studentName || 'N/A'}`, 20, currentY);
    currentY += 8;
    doc.text(`Course Name: ${user.courseDetail.courseName.courseName} (${user.courseDetail.conceptName.conceptName})`, 20, currentY);
    currentY += 8;
    doc.text(`Course Fee: ${user.otherDetails.amountToPay}`, 20, currentY);
    currentY += lineHeight;
  

                    // After adding the last course fee, move currentY for proper spacing
          currentY += 10;

          // Set font to bold and increase font size before adding "STATEMENT"
          doc.setFont("helvetica", "bold");  // Set to bold font
          doc.setFontSize(12);  // Adjust size if needed

          doc.text(`STATEMENT`, 90, currentY);

          // Reset the font to normal for the rest of the document
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);  // Adjust back to normal font size
          currentY += 10;  // Space after "STATEMENT"

    // Table Headers
    const headers = ["S.No", "Received Date", "Amount"];
    const columnWidths = [30, 80, 80]; // Adjust column widths for proper alignment
    const startX = 20;
    
    checkPageHeight(10); // Check height for headers
    addColoredRectangle(startX - 10, currentY - 5, columnWidths.reduce((a, b) => a + b, 0), 10, '#e0e0e0'); // Light gray background
    headers.forEach((header, index) => {
      doc.text(header, startX + columnWidths.slice(0, index).reduce((a, b) => a + b, 0), currentY);
    });
    currentY += 15;
  
    // Table Rows for Transactions
    let totalPaid = 0;
    let rowCount = 0; // Counter for all rows
    const tableWidth = columnWidths.reduce((a, b) => a + b, 0); // Total width of the table
  
    user?.transactions?.forEach((transaction) => {
      // Skip the row if the transaction amount is zero
      if (transaction.amount === 0) return;
  
      // Check if new page is needed for each transaction
      checkPageHeight(lineHeight);
  
      // Increment rowCount for each transaction
      rowCount++;
      const y = currentY;
  
      // Format the received date
      const receivedDate = new Date(transaction.date);
      const formattedReceivedDate = `${String(receivedDate.getDate()).padStart(2, '0')}/${String(receivedDate.getMonth() + 1).padStart(2, '0')}/${receivedDate.getFullYear()}`;
  
      // Draw transaction background with alternating row colors
      const backgroundColor = (rowCount % 2 === 0) ? '#f9f9f9' : '#ffffff';
      addColoredRectangle(startX - 10, y - 5, tableWidth, 10, backgroundColor);
  
      // Add table data
      doc.text(`${rowCount}`, startX, y); // S.No
      doc.text(formattedReceivedDate, startX + columnWidths[0], y); // Received Date
      doc.text(`${transaction.amount.toFixed(2)}`, startX + columnWidths[0] + columnWidths[1], y); // Amount
  
      totalPaid += transaction.amount; // Accumulate total paid
      currentY += lineHeight; // Move to next row
    });
  
    // Summary Section
    checkPageHeight(30); // Check height for summary section
    const summaryY = currentY + lineHeight;
    doc.setFontSize(8);
  
    if (totalPaid < user.otherDetails.amountToPay) {
      const totalRemaining = user.otherDetails.amountToPay - totalPaid;
     // doc.text(`Total Amount Pending: ${totalRemaining.toFixed(2)}`, 10, summaryY);
      currentY += lineHeight;
    }
  
    const gstPercentage = user.courseDetail.courseName.gst;
    if (gstPercentage > 0) {
      const courseFee = user.otherDetails.amountToPay;
      const gstAmount = (courseFee * gstPercentage / 100).toFixed(2);
      const totalPayable = (parseFloat(courseFee) + parseFloat(gstAmount)).toFixed(2);
  
      doc.text(`It Includes GST (${gstPercentage}%)`, 10, summaryY + lineHeight);
      currentY += lineHeight;
    }
  
    // Footer and Authorized Signatory
    checkPageHeight(40); // Check height for footer
    const footerY = currentY + 20;
    doc.setFontSize(8);
    doc.text("Authorised Signatory", 160, footerY);

    doc.text("The amount paid is strictly non-refundable under any circumstances.", 10, footerY + 20);
doc.text("The payment or associated benefits cannot be transferred to another individual.", 10, footerY + 25);
doc.text("The course TIA-BRAIN is valid for 4 months from the date of issuance.", 10, footerY + 30);

    doc.text("For more information, visit: www.tiaedu.in", 105, footerY + 40, null, null, "center");
  
    // Save the PDF
    doc.save(`Receipt_${user.personalInfo.studentName}.pdf`);
  };
  

  return (
    <StudentadmissionContainer>
      {userRole=="admin" &&(
        
      <Button
        variant="outlined"
        className="download-btn"
        onClick={handleDownload} // Call your download function here
        sx={{
          borderRadius: "10px",
          marginLeft: "1rem",
          color: "#f00d88",
          borderColor: "#f00d88",
          // Customize color as needed
        }}
        disabled={filteredData?.length === 0}
      >
        Download Excel
      </Button>)}
      <CenterGroup>
      <TextField
  label="Month"
  variant="outlined"
  select
  value={filterMonth}
  onChange={handleFilterMonth}
  size="small"
  sx={{
    '& .MuiInputBase-root': {
      color: 'white', // Change text color
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#36283a', // Change background color
    },
    '& .MuiInputLabel-root': {
      color: '#fff', // Change label color
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc', // Change border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff', // Border color on hover
    },
  }}
>
  <MenuItem value="All">All</MenuItem>
  {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
    <MenuItem key={month} value={month < 10 ? `0${month}` : `${month}`}>
      {moment().month(month - 1).format("MMMM")}
    </MenuItem>
  ))}
</TextField>
<TextField
  label="Year"
  variant="outlined"
  select
  value={filterYear}
  onChange={handleFilterYear}
  size="small"
  sx={{
    '& .MuiInputBase-root': {
      color: 'white', // Change text color
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#36283a', // Change background color
    },
    '& .MuiInputLabel-root': {
      color: '#fff', // Change label color
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc', // Change border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff', // Border color on hover
    },
  }}
>
  <MenuItem value="All">All</MenuItem>
  {years.map((year) => (
    <MenuItem key={year} value={year.toString()}>
      {year}
    </MenuItem>
  ))}
</TextField>
        </CenterGroup>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                S No
                </th>
                <th className="t-head" scope="col">
                  Student Name
                </th>
                {userRole == "admin" && (
                   <th className="t-head" scope="col">
                   Franchise Owner Email
                 </th>
                 

                )}
                {userRole == "admin" && (
                   <th className="t-head" scope="col">
                   Joined date
                 </th>
                 
                 
                )}
                <th className="t-head" scope="col">
                  Parents Number
                </th>
                <th className="t-head" scope="col">
                  Course Name
                </th>
                <th className="t-head" scope="col">
                  Concept Name
                </th>
                <th className="t-head" scope="col">
                  GST
                </th>
                <th className="t-head" scope="col">
                  Course Fee + GST
                </th>
                <th className="t-head" scope="col">
                  Payment Paid
                </th>
                <th className="t-head" scope="col">
                  Payment Pending
                </th>
                {userRole == "branchstaff" && (
                  <th className="t-head rounded-end-3" scope="col">
                    Actions
                  </th>
                )}
                {userRole == "franchise" && (
                  <th className="t-head rounded-end-3" scope="col">
                    Actions
                  </th>
                )}
                <th className="t-head" scope="col">
                View Stats
                </th>
                <th className="t-head" scope="col">
                  Payment Status
                </th>
                {userRole !== "admin" && (
                <th className="t-head" scope="col">
                  View Status
                </th>
                )}
                <th className="t-head" scope="col">
                  Profile
                </th>
               
                {userRole == "franchise" && (
                  <th className="t-head rounded-end-3" scope="col">
                    Download Receipt
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="table-dark">
              {paginatedData?.map((user, i) => {
                const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                return (
                  <tr>
                    <td>{continuousIndex}</td>
                    <td>{user.personalInfo.studentName}</td>
                    {userRole == "admin" && (
                      <td>{user.courseDetail.branchName.branchOwnerEmail}</td>

                    )}
                    {userRole === "admin" && (
  <td>{new Date(user.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })}</td>
)}

                    <td>
                      {user.familyDetails.fathersPhone},
                      {user.familyDetails.mothersPhone}
                    </td>
                    <td>{user.courseDetail.courseName.courseName}</td>
                    <td>{user.courseDetail.conceptName.conceptName}</td>
                    <td>{user.courseDetail.courseName.gst}</td>
                    <td>₹{user.otherDetails?.amountToPay || 0}</td>
                    <td>₹{user.otherDetails?.amountPaid || 0}</td>
                    <td>
                      ₹
                      {paymentPending(
                        user.otherDetails?.amountToPay || 0,
                        user.otherDetails?.amountPaid || 0
                      )}
                    </td>
                    {userRole == "franchise" && (
                      <td>
                        <div
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "green",
                            border: "1px solid green",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          
                            onClick={() => handleClickOpen(user)}
                        
                        >
                       PAY
                        </div>
                      </td>
                    )}
                    {userRole == "branchstaff" && (
                       <td>
                       <div
                         className="badge"
                         style={{
                           height: "80%",
                           width: "100%",
                           color: "green",
                           border: "1px solid green",
                           padding: "0.6rem",
                           textAlign: "center",
                           background: "transparent",
                           cursor: "pointer",
                         }}
                         
                           onClick={() => handleClickOpen(user)}
                       
                       >
                      PAY
                       </div>
                     </td>
                    )}
                    <td>
                      <button
                        className="badge"
                        onClick={() => {
                          setViewStatsContainer(user);
                        }}
                        style={{
                          height: "80%",
                          width: "100%",
                          color: "white",
                          border: "1px solid white",
                          padding: "0.6rem",
                          textAlign: "center",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        View Stats
                      </button>
                    </td>
                    {user.otherDetails.status == "paid" && (
                      <td>
                        <span
                          class="badge "
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "#31c127",
                            border: "1px solid #31c127",
                            padding: "0.6rem",
                            textAlign: "center",
                          }}
                        >
                          Paid
                        </span>
                      </td>
                    )}{" "}
                    {user.otherDetails.status == "pending" && (
                      <td>
                        <span
                          class="badge "
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "#d6eb3a",
                            border: "1px solid #d6eb3a",
                            padding: "0.6rem",
                            textAlign: "center",
                          }}
                        >
                          Pending
                        </span>
                      </td>
                    )}
                     {userRole !== "admin" && (
                    <td>
                    <div className="button-group">
                      {user.status === "discontinued" ? (
                        <button className="btn selected-discontinued">Discontinue</button>
                      ) : (
                        <button
                          className="btn outlined-error"
                          onClick={() => amountStatus(user, "discontinued")}
                        >
                          Discontinue
                        </button>
                      )}
                      {user.status === "studying" ? (
                        <button className="btn selected-studying">Studying</button>
                      ) : (
                        <button
                          className="btn outlined-studying"
                          onClick={() => amountStatus(user, "studying")}
                        >
                          Studying
                        </button>
                      )}
                      {user.status === "completed" ? (
                        <button className="btn selected-completed">Completed</button>
                      ) : (
                        <button
                          className="btn outlined-success"
                          onClick={() => amountStatus(user, "completed")}
                        >
                          Completed
                        </button>
                      )}
                    </div>
                  </td>
                  
                     )}
                    <td>
                      <button
                        onClick={() => {
                          setProfileViewData(user);
                          navigate("/studentprofile");
                        }}
                        className="badge"
                        style={{
                          height: "80%",
                          width: "100%",
                          color: "white",
                          border: "1px solid white",
                          padding: "0.6rem",
                          textAlign: "center",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        View Profile
                      </button>
                    </td>
                    {userRole == "franchise" && (
                     
                        <td >
                          
                          <DownloadingIcon
                            style={{ color: "#f00d88" }}
                            onClick={() =>
                              downloadReceipt(
                                user,
                                user.otherDetails.amountPaid,
                                user.otherDetails.receivedDate
                              )
                            }
                          />
                        
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const amount = formJson.amount;
                console.log(amount);
                payAmount(amount);
                handleClose();
              },
            }}
          >
            <DialogTitle> Payment</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                name="amount"
                label="Amount"
                type="number"
                fullWidth
                variant="standard"
                inputProps={{
                  max:
                    user.otherDetails?.amountToPay -
                    user.otherDetails?.amountPaid,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Pay</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </div>
      <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
        <Paginations
          count={getTotalPages(userData)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="secondary"
        />
      </Stack>
    </StudentadmissionContainer>
  );
}

function Studentcompleted({ filterData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let [userData, setUserData] = useState([]);
  const [open, setOpen] = React.useState(false);
  let [user, setUser] = useState({});
  const [feedbackContainer, setFeedbackContainer] = useState(false);
  const { userToken, userRole, profileData, setProfileViewData } =
    useGlobalContext();

  console.log("okok", userData);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchFranchise = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response;
      if (userRole === "admin" || userRole === "hos") {
        response = await fetchData(`${url}/students/admin/completed`, header);
      } else if (userRole == "branchstaff") {
        response = await fetchData(
          `${url}/students/completed/franchise/${profileData.profileData.otherDetails.email}`,
          header
        );
      } else {
        response = await fetchData(
          `${url}/students/completed/franchise/${profileData.profileData.email}`,
          header
        );
      }
      console.log(response);
      setUserData(response);
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchFranchise();
  }, []);

  const openModal = (val, studentName) => {
    val ? setFeedbackContainer(true) : setFeedbackContainer(false);
    setIsModalOpen(true);
    console.log("Student Name:", studentName); // Print the student's name
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const StudentcompletedContainer = styled.div`
  .download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
    .mt-3 {
      margin-top: 1rem !important;
    }
    .scroll {
      overflow-y: scroll;
      height: 100%;
    }
    .scroll::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    .scroll::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background-color: transparent;
    }
    .scroll::-webkit-scrollbar-button {
      height: 16px;
    }
    .t-head {
      text-align: center;
    }
    td {
      text-align: center;
    }
    #table-container {
      background-color: #25272d;
      margin-top: 3rem;
      padding: 2rem;
      border-radius: 10px;
      height: 70vh;
      overflow-y: scroll;
    }
    .t-head {
      color: white !important;
      background-color: #18171b !important;
      padding: 1rem !important;
      text-align: center;
    }
    .rounded-corners {
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 10px;
    }
    .rounded-corners th,
    .rounded-corners td {
      border: 1px solid black;
    }
    thead tr {
      border-bottom: 1rem solid #25272d;
    }
    tr {
      border-color: #25272d;
    }
    .table > :not(caption) > * > * {
      background-color: #25272d;
    }
    td {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .btn-group label.btn {
      font-size: 0.7rem !important; /* Decrease font size inside buttons */
      padding: 0.5rem 0.5rem !important; /* Adjust button padding */
    }
    .btn-check:checked + .btn {
      background-color: var(--navbar-dark-primary);
      color: white;
      border-color: var(--navbar-dark-primary);
    }
    .btn-check:checked + .btn.red {
      background-color: red; /* Red color */
    }
    .btn-check:checked + .btn.warning {
      background-color: orange; /* Warning color (orange) */
    }
    .btn-check:checked + .btn.green {
      background-color: green; /* Green color */
    }
  `;
  const Paginations = styled(Pagination)`
    color: white; // Set text color to white
    & .MuiPaginationItem-root {
      color: white; // Ensure pagination items have white text
    }
    & .MuiPaginationItem-root.Mui-selected {
      background-color: #333; // Optionally, set background for selected item
      color: white; // Ensure selected pagination item has white text
    }
  `;

  const [value1, setValue1] = React.useState(0);
  const [value2, setValue2] = React.useState(0);
  const [value3, setValue3] = React.useState(0);
  const [value4, setValue4] = React.useState(0);
  const [value5, setValue5] = React.useState(0);
  const [ids, setIds] = React.useState("");

  const [data, setData] = useState();
  let page = useRef();
  const header = {
    userRole: userRole,
    userToken: userToken,
  };

  const handleFeedBack = async () => {
    console.log(ids);
    console.log("sasij");
    console.log(header);
    try {
      const newData = {
        feedback1: `${value1}`,
        feedback2: `${value2}`,
        feedback3: `${value3}`,
        feedback4: `${value4}`,
        feedback5: `${value5}`,
        feedBacks: "completed",
      };
      await putData(`${url}/students/feedBack/` + ids, newData, header);
      console.log(header);
      console.log("completted");
      enqueueSnackbar("updated", { variant: "success" });
      window.location.reload();
    } catch (err) {
      console.log("err");
      enqueueSnackbar(`${err}`, { variant: "error" });
    }
  };
  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials(
      "AKIAY2YS5NFNTUKGZ7MJ",
      "JiR0WQe6lZC7yg1UrKOIWIldAzDt0HM3wFkWXbSs"
    ),
  });

  const s3 = new AWS.S3();

  const imageURL = async (key) => {
    try {
      let url = await s3.getSignedUrl("getObject", {
        Bucket: "tiatest-development",
        Key: key,
      });
      console.log(url);
      console.log("ok");
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchImageAsBlob = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };
  const [name, setName] = useState("");
  const [cname, setCname] = useState("");
  const [startdate, setStartdate] = useState("");
  const f = async (key, name,cname,startdate) => {
    const reUrl = await imageURL(key);
    const newUrl = await fetchImageAsBlob(reUrl);
    console.log(URL.createObjectURL(newUrl));
    setData(URL.createObjectURL(newUrl));
    setName(name);
    setCname(cname);
    setStartdate(new Date(startdate));  // Convert to Date object


  };
// Function to format date as DD/MM/YYYY
const formatDate = (date) => {
  // Check if the date is a valid Date object
  const d = new Date(date);  // Ensures date can be a string or Date object
  if (isNaN(d.getTime())) {  // Invalid date case
    return "Invalid Date";
  }

  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = d.getFullYear();

  return `${day}/${month}/${year}`;
};

const navigate = useNavigate();

  const handilClick = async () => {
    const element = page.current;

    if (element) {
      try {
        // Capture the element as a canvas
        const canvas = await html2canvas(element, {
          useCORS: true, // Handle CORS if needed
          scale: 2, // Improve image quality
        });

        const data = canvas.toDataURL("image/png");

        // Create a new jsPDF instance
        const pdf = new jsPDF({
          orientation: "landscape", // Use 'portrait' if your content is vertical
          unit: "pt", // Points are typically used for PDF dimensions
          format: [canvas.width, canvas.height],
        });

        // Add the captured image to the PDF
        pdf.addImage(data, "PNG", 0, 0, canvas.width, canvas.height);

        // Save the PDF with a specific filename
        pdf.save("certificate.pdf");
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    } else {
      console.warn("Element reference is null.");
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Adjust based on your requirement

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getTotalPages = (data) => {
    return Math.ceil(data.length / itemsPerPage);
  };


  // Filter userData based on filterData prop (search)
  const filteredData = userData.filter((user) => {
    const studentName = user.personalInfo.studentName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const parentNumber1 = user.familyDetails.fathersPhone
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const parentNumber2 = user.familyDetails.mothersPhone
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const courseName = user.courseDetail.courseName.courseName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    const conceptName = user.courseDetail.conceptName.conceptName
      .toLowerCase()
      .includes(filterData.toLowerCase());
    return (
      studentName ||
      parentNumber1 ||
      parentNumber2 ||
      conceptName ||
      conceptName
    );
  });
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filterData.map((user, i) => ({
        'S.no': i + 1,
        'Student Name': user.personalInfo.studentName,
        'Phone Number': `${user.familyDetails.fathersPhone}, ${user.familyDetails.mothersPhone}`,
        'Course Name': user.courseDetail.courseName.courseName,
        'Concept Name': user.courseDetail.conceptName.conceptName,
        'Course Fee': `₹${user.otherDetails.amountToPay}`,
        'Payment Status': user.otherDetails.status,
        'Feedback': user?.feedBackquestion?.feedBacks === "notyet" ? 'Not Yet' : 'Provided',
        'Certificate': user?.feedBackquestion?.feedBacks === "notyet" ? 'N/A' : 'Available',
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');

    // Export the workbook
    XLSX.writeFile(workbook, 'StudentData.xlsx');
  };
  return (
    <StudentcompletedContainer>
      <div>
      {userRole=="admin" &&(
      <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
              color:"#f00d88",
              borderColor:"#f00d88",
             // Customize color as needed
              
            }}
            disabled={filteredData?.length===0}
          >
            Download Excel
          </Button>)}
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                  S No
                  </th>
                  <th className="t-head" scope="col">
                    Student Name
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    Course Name
                  </th>
                  <th className="t-head" scope="col">
                    Concept Name
                  </th>
                  <th className="t-head" scope="col">
                    Course fee
                  </th>
                  <th className="t-head" scope="col">
                    Payment Status
                  </th>
                  
                {userRole !== "admin" && (
                  <th className="t-head" scope="col">
                    Feedback
                  </th>
                )}
                 <th className="t-head" scope="col">
                  Profile
                </th>
                  {/* <th className="t-head rounded-end-3" scope="col">
                    Certificate
                  </th> */}
                </tr>
              </thead>
              <tbody className="table-dark">
                {paginatedData?.map((user, i) => {
                  const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                  return (
                    <tr>
                      <td>{continuousIndex}</td>
                      <td>{user.personalInfo.studentName}</td>
                      <td>
                        {user.familyDetails.fathersPhone},
                        {user.familyDetails.mothersPhone}
                      </td>
                      <td>{user.courseDetail.courseName.courseName}</td>
                      <td>{user.courseDetail.conceptName.conceptName}</td>
                      <td>₹{user.otherDetails.amountToPay}</td>
                      <td>
                        {user.otherDetails.status == "paid" && (
                          <span
                            className="badge"
                            style={{
                              height: "80%",
                              width: "100%",
                              color: "#31c127",
                              border: "1px solid #31c127",
                              padding: "0.6rem",
                              textAlign: "center",
                            }}
                          >
                            Paid
                          </span>
                        )}
                        {user.otherDetails.status == "pending" && (
                          <span
                            className="badge"
                            style={{
                              height: "80%",
                              width: "100%",
                              color: "#d6eb3a",
                              border: "1px solid #d6eb3a",
                              padding: "0.6rem",
                              textAlign: "center",
                            }}
                          >
                            Pending
                          </span>
                        )}
                      </td>
                      
                {userRole !== "admin" && (
                      <td>
                         <a href="#" onClick={() => openModal(true)}>
                                  <button
                                    onClick={() => setIds(user._id)}
                                    className="badge"
                                    style={{
                                      height: "80%",
                                      width: "100%",
                                      color: user?.feedBackquestion?.feedBacks === "completed" ? "green" : "white", // Disabled color if feedback provided
                                      border: "1px solid green",
                                      padding: "0.6rem",
                                      textAlign: "center",
                                      background: user?.feedBackquestion?.feedBacks === "completed" ? "#e0e0e0" : "transparent", // Disabled background if feedback provided
                                      cursor: user?.feedBackquestion?.feedBacks === "completed" ? "not-allowed" : "pointer", // Disabled cursor
                                    }}
                                    disabled={user?.feedBackquestion?.feedBacks === "completed"} // Disable button if feedback is completed
                                  >
                                    {user?.feedBackquestion?.feedBacks === "completed" ? "Feedback Submitted" : "Take Feedback"}
                                  </button>
                                </a>
                          </td>
                )}
                   <td>
                      <button
                        onClick={() => {
                          setProfileViewData(user);
                          navigate("/studentprofile");
                        }}
                        className="badge"
                        style={{
                          height: "80%",
                          width: "100%",
                          color: "white",
                          border: "1px solid white",
                          padding: "0.6rem",
                          textAlign: "center",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        View Profile
                      </button>
                    </td>
                      {/* <td>
                        {user?.feedBackquestion?.feedBacks === "notyet" ? (
                          <button
                            style={{
                              height: "80%",
                              width: "100%",
                              color: "red",
                              border: "1px solid red",
                              padding: "0.4rem",
                              textAlign: "center",
                              fontSize:"small",
                              background: "transparent",
                              whiteSpace:"nowrap",
                              cursor: "pointer",
                            }}
                          >
                            View Certificate
                          </button>
                        ) : (
                          <div
                            onClick={() =>
                              f(
                                user.imageACCESSKEY,
                                user.personalInfo.studentName,
                                user.courseDetail.courseName.courseName,
                                user.createdAt
                              )
                            }
                          >
                            <button
                              className="badge"
                              style={{
                                height: "80%",
                                width: "100%",
                                color: "white",
                                border: "1px solid white",
                                padding: "0.6rem",
                                textAlign: "center",
                                background: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() => openModal(false)}
                            >
                              View Certificate
                            </button>
                          </div>
                        )}
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={getTotalPages(userData)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
        {/* Modal component */}
        
        <div
          className={`modal ${isModalOpen ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ display: isModalOpen ? "block" : "none" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {feedbackContainer ? (
              <>
              <div className="modal-header">
                <h5 className="modal-title">Feedback</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {/* Feedback form content */}
                <div className="d-flex justify-content-between">
                  <Typography component="legend">How much did your child enjoy the class overall?</Typography>
                  <Rating
                    name="enjoyment"
                    value={value1}
                    onChange={(event, newValue) => setValue1(newValue)}
                  />
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <Typography component="legend">Did the class keep your child engaged and interested throughout?</Typography>
                  <Rating
                    name="engagement"
                    value={value2}
                    onChange={(event, newValue) => setValue2(newValue)}
                  />
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <Typography component="legend">How would you rate the TIA trainer?</Typography>
                  <Rating
                    name="trainer"
                    value={value3}
                    onChange={(event, newValue) => setValue3(newValue)}
                  />
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <Typography component="legend">Did the teacher explain about the MIT report?</Typography>
                  <Rating
                    name="mitReport"
                    value={value4}
                    onChange={(event, newValue) => setValue4(newValue)}
                  />
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <Typography component="legend">Would you recommend this class to other parents?</Typography>
                  <Rating
                    name="recommendation"
                    value={value5}
                    onChange={(event, newValue) => setValue5(newValue)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => handleFeedBack(user._id)}
                  disabled={!value1 || !value2 || !value3 || !value4 || !value5}  // Disable if any rating is missing
                >
                  Submit Feedback
                </button>
              </div>
            </>
            
              ) : (
                
                <>
                  <div className="modal-header">
                    <h5 className="modal-title">Certificates</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={closeModal}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                        padding: "0.6rem",
                      }}
                    >
                      <div
                        style={{
                          position: "relative", // Set relative positioning on the container
                          height: "100%",
                          width: "100%",
                          textAlign: "center",
                        }}
                        ref={page}
                      >
                        <img
                          src={certificareImage}
                          alt="Certificate"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            width: "auto",
                            height: "auto",
                          }}
                        />
                        {/* Student Name */}
                        <div
  style={{
    position: "absolute",
    top: "46.5%", // Adjust top and left values as needed
    left: "55%",
    transform: "translate(-50%, -50%)",
    color: "black",
    fontSize: "8px",
    fontWeight: "bold",      // Makes the text bold
    textTransform: "uppercase",  // Converts text to uppercase
  }}
>
  {name}
</div>

                        {/* Duration */}
                        <div
                          style={{
                            position: "absolute",
                            top: "58%", // Adjust top and left values as needed
                            left: "43%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "8px",
                          }}
                        >
                        {formatDate(startdate)} - {formatDate(new Date())}
                        </div>
                        {/* Place */}
                        <div
                          style={{
                            position: "absolute",
                            top: "62.5%", // Adjust top and left values as needed
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "8px",
                          }}
                        >
                         TALENT INITIATORS & ACCELERATORS INDIA Pvt Ltd
                        </div>
                        {/* Date */}
                        <div
                          style={{
                            position: "absolute",
                            top: "86%",
                            left: "34.5%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "7px",
                          }}
                        >
                          {/* This will be updated in the PDF generation */}
                          {formatDate(new Date())}
                        </div>
                        {/* Additional Image */}
                        <img
                          src={data} // Replace with the source of the additional image
                          alt="Additional"
                          style={{
                            position: "absolute",
                            top: "28%", // Adjust top and left values as needed
                            left: "86%",
                            width: "15%", // Adjust the width as needed
                            height: "25%", // Maintain aspect ratio
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handilClick}
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </StudentcompletedContainer>
  );
}

function Viewstats({ viewStats, setViewStatsContainer }) {
  const ViewstatsContainer = styled.div`
   background-color: var(--background);
   padding:3rem;

    .mt-3 {
      margin-top: 1rem !important;
    }
    .scroll {
      overflow-y: scroll;
      height: 100%;
    }
    .scroll::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    .scroll::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background-color: transparent;
    }
    .scroll::-webkit-scrollbar-button {
      height: 16px;
    }
    .t-head {
      text-align: center;
    }
    td {
      text-align: center;
    }
    #table-container {
      background-color: #25272d;
      margin-top: 3rem;
      padding: 2rem;
      border-radius: 10px;
      height: 80vh;
      overflow-y: scroll;
    }
    .t-head {
      color: white !important;
      background-color: #18171b !important;
      padding: 1rem !important;
      text-align: center;
    }
    .rounded-corners {
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 10px;
    }
    .rounded-corners th,
    .rounded-corners td {
      border: 1px solid black;
    }
    thead tr {
      border-bottom: 1rem solid #25272d;
    }
    tr {
      border-color: #25272d;
    }
    .table > :not(caption) > * > * {
      background-color: #25272d;
    }
    td {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .btn-group label.btn {
      font-size: 0.7rem !important; /* Decrease font size inside buttons */
      padding: 0.5rem 0.5rem !important; /* Adjust button padding */
    }
    .btn-check:checked + .btn {
      background-color: var(--navbar-dark-primary);
      color: white;
      border-color: var(--navbar-dark-primary);
    }
    .btn-check:checked + .btn.red {
      background-color: red; /* Red color */
    }
    .btn-check:checked + .btn.warning {
      background-color: orange; /* Warning color (orange) */
    }
    .btn-check:checked + .btn.green {
      background-color: green; /* Green color */
    }
  `;
  console.log(viewStats);
  return (
    <ViewstatsContainer>
      <Button variant="contained" onClick={() => setViewStatsContainer(false)}>
        Back
      </Button>
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                S No
                </th>
                <th className="t-head" scope="col">
                  Student Name
                </th>

                <th className="t-head" scope="col">
                  Update Amount
                </th>
                {/* <th className="t-head" scope="col">
                  Payment Paid
                </th>
                <th className="t-head" scope="col">
                  Payment Pending
                </th> */}
                <th className="t-head" scope="col">
                  Update Time
                </th>
                <th className="t-head" scope="col">
                  Update Date
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {viewStats?.transactions?.map((transcation, i) => {
                return (
                  <>
                  {transcation.amount !== 0 && (
                    <tr>
                      <td>{++i}</td>
                      <td>{viewStats.personalInfo.studentName}</td>
                      <td>{transcation.amount}</td>
                      <td>{format(transcation.date, "hh:mm:ss a")}</td>
                      <td>{format(transcation.date, "dd-MM-yyyy")}</td>
                    </tr>
                  )}
                  </>
                );
                
              })}
              <tr></tr>
              {/* <tr>
                <td>1</td>
                <td>Bubalan S</td>
                <td>8667859174</td>
                <td>Full Stack</td>
                <td>15000</td>
                <td>1000</td>
                <td>2000</td>
                <td>13000</td>
                <td>11:45 AM</td>
                <td>09/07/2024</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </ViewstatsContainer>
  );
}

export { Studentadmission, Studentcompleted, Viewstats };
