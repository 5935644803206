import React, { useEffect, useState } from "react";
import icon from "../../../assets/images/Tia - font.png";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { Lock } from '@mui/icons-material';
import { fetchData, imageURL } from "../../../Api";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";

function MiniNavBar({ user, selected }) {
  const handleChangePassword = () => {
    handleClose(); // Close the menu
    navigate('/changepassword'); // Navigate to the Change Password component
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { profileImage, profileData, updateUser, userToken, userRole } =
    useGlobalContext();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [setting, setSettings] = useState();
  const fetchSettings = async () => {
    const headers = {
      userRole: "admin",
      userToken: userToken,
    };
    try {
      let data = await fetchData(`${URL}/admin/settings`, headers);
      console.log(data);
      setSettings(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const LogOutUser = () => {
    navigate("/");
    window.location.reload();
    localStorage.removeItem("data");
  };
  if (userRole == "admin") {
    return (
      <NavBarSide id="miniNav">
        <nav
          style={{ width: "5rem" }}
          class="navbar navbar-dark   fixed-top mt-3"
        >
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="offcanvas offcanvas-start text-bg-dark"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <div id="nav-header">
                  <div id="nav-image-head">
                    <img src={icon} alt="" />
                  </div>
                </div>
                <hr />
                <div id="nav-user-img" >
                {/* <div id="nav-user-img" onClick={() => navigate("/vipprofile")}> */}
                  <div id="img-sec">
                    <img src={icon} alt="" />
                    <span>
                      <b>ADMIN PROFILE</b>
                    </span>
                  </div>
                </div>
                <h6 className="pb-2 pt-2">
                  {profileData.profileData.userName}
                </h6>
                <div className="scrol" id="nav-content">
                  <div
                    id={
                      selected == "dashboard"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/dashboard")}
                  >
                    <div class="line"></div>
                    <span class="side-text">DASH BOARD</span>
                  </div>
                  <div
                    id={
                      selected == "conceptdetails"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/conceptdetails")}
                  >
                    <div class="line"></div>
                    <span class="side-text">CONCEPT DETAILS</span>
                  </div>
                  <div
                    id={
                      selected == "coursedetails"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/coursedetails")}
                  >
                    <div class="line"></div>
                    <span class="side-text">COURSE DETAILS</span>
                  </div>
                  <div
                    id={
                      selected == "hosstaff"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/hosstaff")}
                  >
                    <div class="line"></div>
                    <span class="side-text">HOS STAFF</span>
                  </div>
                  <div
                    id={
                      selected == "vipdetails"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/vipdetails")}
                  >
                    <div class="line"></div>
                    <span class="side-text">VIP</span>
                  </div>
                  <div
                    id={
                      selected == "vipfranchisedetails"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/vipfranchisedetails")}
                  >
                    <div class="line"></div>
                    <span class="side-text">VIP FRANCHISE</span>
                  </div>
                  <div
                    id={
                      selected == "franchisedetails"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/franchisedetails")}
                  >
                    <div class="line"></div>
                    <span class="side-text">FRANCHISE</span>
                  </div>
                  <div
                    id={
                      selected == "studentsdetails"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/studentsdetails")}
                  >
                    <div class="line"></div>
                    <span class="side-text">STUDENTS</span>
                  </div>
                  <div
                    id={
                      selected == "accounts"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/accounts")}
                  >
                    <div class="line"></div>
                    <span class="side-text">ACCOUNTS</span>
                  </div>
                  <div
              id={
                selected == "mediaupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/mediaupdates")}
            >
              <div class="line"></div>
              <span class="side-text">MEDIA</span>
            </div>
                  <div
                    id={
                      selected == "crm"
                        ? "nav-content-highlight"
                        : "nav-content-normal"
                    }
                    onClick={() => navigate("/crm")}
                  >
                    <div class="line"></div>
                    <span class="side-text">CRM</span>
                  </div>
                  <div
              id={
                selected == "message"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/message")}
            >
              <div class="line"></div>
              <span class="side-text">CEO MESSAGES</span>
            </div>
            <div
              id={
                selected == ""
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
            >
              <span class="side-text">
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <b style={{ color: "white" }}>SETTINGS</b>
                    <Settings fontSize="medium" sx={{ color: "pink" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      bgcolor: "#25272d",
                      color: "white" ,
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#25272d",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Divider />
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <Lock fontSize="small" sx={{ color: "pink" }} />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/settings")}>
                    <ListItemIcon>
                      <Settings fontSize="small" sx={{ color: "pink" }}/>
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                  <MenuItem onClick={LogOutUser}>
                    <ListItemIcon>
                      <Logout fontSize="small" sx={{ color: "pink" }} />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </span>
            </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </NavBarSide>
    );
  } else if (userRole == "hos") {
    return (
      <NavBarSide id="miniNav">
        <nav
          style={{ width: "5rem" }}
          class="navbar navbar-dark   fixed-top mt-3"
        >
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="offcanvas offcanvas-start text-bg-dark"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <div id="nav-header">
                  <div id="nav-image-head">
                    <img src={icon} alt="" />
                  </div>
                </div>
                <hr />
                <div id="nav-user-img" >
                {/* <div id="nav-user-img" onClick={() => navigate("/vipprofile")}> */}
                  <div id="img-sec">
                    <img
                      src={icon}
                      alt=""
                    />
                    <span>
                      <b>HOS</b>
                    </span>
                  </div>
                </div>
                <h6 className="pb-2 pt-2">{profileData.profileData.userName}</h6>
                <div className="scrol" id="nav-content">
            <div
              id={
                selected == "dashboard"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/dashboard")}
            >
              <div class="line"></div>
              <span class="side-text">DASH BOARD</span>
            </div>
            <div
              id={
                selected == "courselist"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/courselist")}
            >
              <div class="line"></div>
              <span class="side-text">COURSE LIST</span>
            </div>

            {profileData.profileData.workingOption.includes("admin") && (
              <>
                <div
                  id={
                    selected == "vipdetails"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/vipdetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">VIP</span>
                </div>
                <div
                  id={
                    selected == "vipfranchisedetails"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/vipfranchisedetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">VIP FRANCHISE</span>
                </div>
                <div
                  id={
                    selected == "franchisedetails"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/franchisedetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">FRANCHISE</span>
                </div>
                <div
                  id={
                    selected == "studentsdetails"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/studentsdetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">STUDENTS</span>
                </div>
                <div
                  id={
                    selected == "accounts"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/accounts")}
                >
                  <div class="line"></div>
                  <span class="side-text">ACCOUNTS</span>
                </div>
              </>
            )}
            {profileData.profileData.workingOption.includes("vip") && (
              <>
                <div
                  id={
                    selected == "vipdetails"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/vipdetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">VIP</span>
                </div>
              </>
            )}
            {profileData.profileData.workingOption.includes("franchise") && (
              <>
                <div
                  id={
                    selected == "franchisedetails"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/franchisedetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">FRANCHISE</span>
                </div>
              </>
            )}
            {profileData.profileData.workingOption.includes("vipFranchise") && (
              <>
                <div
                  id={
                    selected == "vipfranchisedetails"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/vipfranchisedetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">VIP FRANCHISE</span>
                </div>
              </>
            )}
            {profileData.profileData.workingOption.includes("students") && (
              <>
                <div
                  id={
                    selected == "students"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/studentsdetails")}
                >
                  <div class="line"></div>
                  <span class="side-text">STUDENTS</span>
                </div>
              </>
            )}
            {profileData.profileData.workingOption.includes("accounts") && (
              <>
                <div
                  id={
                    selected == "accounts"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/accounts")}
                >
                  <div class="line"></div>
                  <span class="side-text">ACCOUNTS</span>
                </div>
              </>
            )}
             {profileData.profileData.workingOption.includes("media") && (
              <>
                <div
                  id={
                    selected == "mediaupdates"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/mediaupdates")}
                >
                  <div class="line"></div>
                  <span class="side-text">MEDIA</span>
                </div>
              </>
            )}
             {profileData.profileData.workingOption.includes("crm") && (
              <>
                <div
                  id={
                    selected == "crm"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/crm")}
                >
                  <div class="line"></div>
                  <span class="side-text">CRM</span>
                </div>
              </>
            )}
              {profileData.profileData.workingOption.includes("ceomessages") && (
              <>
                <div
                  id={
                    selected == "message"
                      ? "nav-content-highlight"
                      : "nav-content-normal"
                  }
                  onClick={() => navigate("/message")}
                >
                  <div class="line"></div>
                  <span class="side-text">CEO MESSAGES</span>
                </div>
              </>
            )}
           
            <div
              id={
                selected == ""
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
            >
              <span class="side-text">
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <b style={{ color: "white" }}>SETTINGS</b>
                    <Settings  fontSize="small" sx={{ color: "pink" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      bgcolor: "#25272d",
                      color: "white" ,
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#25272d",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Divider />
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <Lock fontSize="small" sx={{ color: "pink" }} />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>

                  <MenuItem onClick={LogOutUser}>
                    <ListItemIcon>
                      <Logout fontSize="small" sx={{ color: "pink" }} />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </span>
            </div>
          </div>
              </div>
            </div>
          </div>
        </nav>
      </NavBarSide>
    );
  } else if (userRole == "vipFranchise") {
    return (
      <NavBarSide id="miniNav">
        <nav
          style={{ width: "5rem" }}
          class="navbar navbar-dark   fixed-top mt-3"
        >
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="offcanvas offcanvas-start text-bg-dark"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <div id="nav-header">
                  <div id="nav-image-head">
                    <img src={icon} alt="" />
                  </div>
                </div>
                <hr />
                <div id="nav-user-img" >
                {/* <div id="nav-user-img" onClick={() => navigate("/userprofileview")}> */}
            <div id="img-sec">
              <img src={icon} alt="" />
              <span style={{ fontSize: "0.5rem" }}>
                <b>VIP FRANCHISE </b>
              </span>
            </div>
          </div>
          <h6 className="pb-2 pt-2">{profileData.profileData.userName}</h6>
                <div className="scrol" id="nav-content">
            <div
              id={
                selected == "dashboard"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/dashboard")}
            >
              <div class="line"></div>
              <span class="side-text">DASH BOARD</span>
            </div>
            <div
              id={
                selected == "courselist"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/courselist")}
            >
              <div class="line"></div>
              <span class="side-text">COURSE LIST</span>
            </div>

            <div
              id={
                selected == "pincoderefferals"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/pincoderefferals")}
            >
              <div class="line"></div>
              <span class="side-text">PINCODE REFERRALS</span>
            </div>
            <div
              id={
                selected == "pincoderevenue"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/pincoderevenue")}
            >
              <div class="line"></div>
              <span class="side-text">PINCODE REVENUE</span>
            </div>
            <div
              id={
                selected == "mediaupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/mediaupdates")}
            >
              <div class="line"></div>
              <span class="side-text">MEDIA UPDATES</span>
            </div>
            

            <div
              id={
                selected == "messag"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
            >
              <div class="line"></div>
              <span class="side-text">
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <b style={{ color: "white" }}>SETTINGS</b>
                    <Settings fontSize="medium" sx={{ color: "pink" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      bgcolor: "#25272d",
                      color: "white" ,
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#25272d",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {profileData.profileData.workingOption[0] ==
                    "vipFranchise" && (
                    <MenuItem
                      onClick={() => {
                        updateUser("vip", userToken, profileImage);
                      }}
                    >
                      <Avatar /> My Vip account
                    </MenuItem>
                  )}

                  <Divider />
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <Lock fontSize="small" sx={{ color: "pink" }} />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                  <MenuItem to>
                   Contact Support - <b>{setting?.vipSupportNumber}</b>
                  </MenuItem>
                  <MenuItem onClick={LogOutUser}>
                    <ListItemIcon>
                      <Logout fontSize="small" sx={{ color: "pink" }}/>
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </span>
            </div>
          </div>
              </div>
            </div>
          </div>
        </nav>
      </NavBarSide>
    );
  } else if (userRole == "vip") {
    return (
      <NavBarSide id="miniNav">
        <nav
          style={{ width: "5rem" }}
          class="navbar navbar-dark   fixed-top mt-3"
        >
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="offcanvas offcanvas-start text-bg-dark"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <div id="nav-header">
                  <div id="nav-image-head">
                    <img src={icon} alt="" />
                  </div>
                </div>
                <hr />
                <div id="nav-user-img" >
                {/* <div id="nav-user-img" onClick={() => navigate("/userprofileview")}> */}
            <div id="img-sec">
              <img src={icon} alt="" />

              <span>
                <b>VIP PROFILE</b>
              </span>
            </div>
          </div>
          <h6 className="pb-2 pt-2">{profileData.profileData.userName}</h6>
          <div className="scrol" id="nav-content">
            <div
              id={
                selected == "dashboard"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/dashboard")}
            >
              <div class="line"></div>
              <span class="side-text">DASH BOARD</span>
            </div>
            <div
              id={
                selected == "courselist"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/courselist")}
            >
              <div class="line"></div>
              <span class="side-text">COURSE LIST</span>
            </div>
            <div
              id={
                selected == "branches"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/branches")}
            >
              <div class="line"></div>
              <span class="side-text">FRANCHISE LIST</span>
            </div>
            <div
              id={
                selected == "studentsdetails"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/studentsdetails")}
            >
              <div class="line"></div>
              <span class="side-text">STUDENTS</span>
            </div>
            <div
              id={
                selected == "referuser"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/referuser")}
            >
              <div class="line"></div>
              <span class="side-text">REFER USER</span>
            </div>
            <div
              id={
                selected == "refereduser"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/refereduser")}
            >
              <div class="line"></div>
              <span class="side-text">REFERED USER</span>
            </div>

            <div
              id={
                selected == "viprevenue"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/viprevenue")}
            >
              <div class="line"></div>
              <span class="side-text">MY REVENUE</span>
            </div>
            <div
              id={
                selected == "crm"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/crm")}
            >
              <div class="line"></div>
              <span class="side-text">CRM</span>
            </div>
            <div
              id={
                selected == "mediaupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/mediaupdates")}
            >
              <div class="line"></div>
              <span class="side-text">MEDIA UPDATES</span>
            </div>
            <div
              id={
                selected == "marketingupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/marketingupdates")}
            >
              <div class="line"></div>
              <span class="side-text">MARKETING UPDATES</span>
            </div>

            <div
              id={
                selected == "message"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
            >
                   <span class="side-text">
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <b style={{ color: "white" }}>SETTINGS</b>
                    <Settings fontSize="medium" sx={{ color: "pink" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      bgcolor: "#25272d",
                      color: "white",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#25272d",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {profileData.profileData.workingOption[0] ==
                    "vipFranchise" && (
                    <MenuItem
                      onClick={() => {
                        updateUser("vipFranchise", userToken, profileImage);
                      }}
                    >
                      <Avatar /> My Vip Franchise account
                    </MenuItem>
                  )}

                  <Divider />
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <Lock fontSize="small" sx={{ color: "pink" }}/>
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                  <MenuItem to>
                   Contact Support - <b>{setting?.vipSupportNumber}</b>
                  </MenuItem>
                  <MenuItem to>Logout</MenuItem>
                  <MenuItem onClick={LogOutUser}>
                    <ListItemIcon>
                      <Logout fontSize="small" sx={{ color: "pink" }}/>
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </NavBarSide>
    );
  } else if (userRole == "franchise") {
    return (
      <NavBarSide id="miniNav">
        <nav
          style={{ width: "5rem" }}
          class="navbar navbar-dark   fixed-top mt-3"
        >
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="offcanvas offcanvas-start text-bg-dark"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <div id="nav-header">
                  <div id="nav-image-head">
                    <img src={icon} alt="" />
                  </div>
                </div>
                <hr />
                {/* <div id="nav-user-img" onClick={() => navigate("/userprofileview")}> */}
                <div id="nav-user-img" >
            <div id="img-sec">
              <img src={icon} alt="" />

              <span>
                <b>Franchise</b>
              </span>
            </div>
          </div>
          <h6 className="pb-2 pt-2">{profileData.profileData.userName}</h6>
          <div className="scrol" id="nav-content">
            <div
              id={
                selected == "dashboard"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/dashboard")}
            >
              <div class="line"></div>
              <span class="side-text">DASH BOARD</span>
            </div>
            <div
              id={
                selected == "courselist"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/courselist")}
            >
              <div class="line"></div>
              <span class="side-text">COURSE LIST</span>
            </div>

            <div
              id={
                selected == "branchstaff"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/branchstaff")}
            >
              <div class="line"></div>
              <span class="side-text">BRANCH STAFF</span>
            </div>
            <div
              id={
                selected == "studentsdetails"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/studentsdetails")}
            >
              <div class="line"></div>
              <span class="side-text">STUDENT DETAIL</span>
            </div>

            <div
              id={
                selected == "crm"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/crm")}
            >
              <div class="line"></div>
              <span class="side-text">CRM</span>
            </div>
            <div
              id={
                selected == "mediaupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/mediaupdates")}
            >
              <div class="line"></div>
              <span class="side-text">MEDIA UPDATES</span>
            </div>
            <div
              id={
                selected == "marketingupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/marketingupdates")}
            >
              <div class="line"></div>
              <span class="side-text">MARKETING UPDATES</span>
            </div>
            <div
              id={
                selected == "message"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
            >
              <span class="side-text">
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <b style={{ color: "white" }}>SETTINGS</b>
                    <Settings  fontSize="medium" sx={{ color: "pink" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      bgcolor: "#25272d",
                      color: "white",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#25272d",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {profileData.profileData.workingOption[0] ==
                    "vipFranchise" && (
                    <MenuItem
                      onClick={() => {
                        updateUser("vipFranchise", userToken, profileImage);
                      }}
                    >
                      <Avatar /> My Vip Franchise account
                    </MenuItem>
                  )}

                  <Divider />
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <Lock fontSize="small" sx={{ color: "pink" }} />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                  <MenuItem to>
                    Contact Support - <b>{setting?.franchiseSupportNumber}</b>
                  </MenuItem>
                  <MenuItem onClick={LogOutUser}>
                    <ListItemIcon>
                      <Logout fontSize="small" sx={{ color: "pink" }}/>
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </span>
            </div>
          </div>
              </div>
            </div>
          </div>
        </nav>
      </NavBarSide>
    );
  } else if (userRole == "branchstaff") {
    return (
      <NavBarSide id="miniNav">
        <nav
          style={{ width: "5rem" }}
          class="navbar navbar-dark   fixed-top mt-3"
        >
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="offcanvas offcanvas-start text-bg-dark"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <div id="nav-header">
                  <div id="nav-image-head">
                    <img src={icon} alt="" />
                  </div>
                </div>
                <hr />
                {/* <div id="nav-user-img" onClick={() => navigate("/userprofileview")}> */}
                <div id="nav-user-img" >
            <div id="img-sec">
              <img src={icon} alt="" />

              <span>
                <b>Branch Staff</b>
              </span>
            </div>
          </div>
          <h6 className="pb-2 pt-2">{profileData.profileData.userName}</h6>
          <div className="scrol" id="nav-content">
            <div
              id={
                selected == "dashboard"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/dashboard")}
            >
              <div class="line"></div>
              <span class="side-text">DASH BOARD</span>
            </div>
            <div
              id={
                selected == "courselist"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/courselist")}
            >
              <div class="line"></div>
              <span class="side-text">COURSE LIST</span>
            </div>
            <div
              id={
                selected == "studentsdetails"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/studentsdetails")}
            >
              <div class="line"></div>
              <span class="side-text">STUDENT DETAIL</span>
            </div>
            <div
              id={
                selected == "crm"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/crm")}
            >
              <div class="line"></div>
              <span class="side-text">CRM</span>
            </div>
            <div
              id={
                selected == "mediaupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/mediaupdates")}
            >
              <div class="line"></div>
              
              <span class="side-text">MEDIA UPDATES</span>
            </div>
            {/* <div
              id={
                selected == "marketingupdates"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
              onClick={() => navigate("/marketingupdates")}
            >
              <div class="line"></div>
              <span class="side-text">MARKETING UPDATES</span>
            </div> */}
            <div
              id={
                selected == "message"
                  ? "nav-content-highlight"
                  : "nav-content-normal"
              }
            >
              <span class="side-text">
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <b style={{ color: "white" }}>SETTINGS</b>
                    <Settings  fontSize="medium" sx={{ color: "pink" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      color: "white",
                      bgcolor: "#25272d",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#25272d",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {profileData.profileData.workingOption[0] ==
                    "vipFranchise" && (
                    <MenuItem
                      onClick={() => {
                        updateUser("vipFranchise", userToken, profileImage);
                      }}
                    >
                      <Avatar /> My Vip Franchise account
                    </MenuItem>
                  )}

                  <Divider />
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <Lock fontSize="small" sx={{ color: "pink" }} />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                  <MenuItem to>
                    Contact Support - <b>{setting?.franchiseSupportNumber}</b>
                  </MenuItem>
                  <MenuItem onClick={LogOutUser}>
                    <ListItemIcon>
                      <Logout fontSize="small" sx={{ color: "pink" }}/>
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </span>
            </div>
          </div>
              </div>
            </div>
          </div>
        </nav>
      </NavBarSide>
    );
  }
}

const NavBarSide = styled.div`
  h6 {
    text-align: center;
  }

  html,
  body {
    margin: 0;
    background: var(--background);
  }

  #nav-bar {
    height: 100vh;
    width: var(--navbar-width);
    left: 1vw;
    top: 1vw;
    background: var(--navbar-dark-primary);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    flex-direction: column;
    color: var(--navbar-light-primary);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    overflow: hidden;
    user-select: none;
    z-index: 10rem;
  }
  @media only screen and (max-width:999px) {
    #nav-bar {
      display: none;
    }
  }
  body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  body::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  body::-webkit-scrollbar-button {
    height: 16px;
  }

  .scrol::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scrol::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scrol::-webkit-scrollbar-button {
    height: 16px;
  }

  #nav-image-head {
    width: 100%;
    height: 4rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  #nav-image-head img {
    height: 90%;
    align-items: center;
  }
  hr {
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    border: none;
    height: 2px;
    width: 90%;
    align-items: center;
    background: black;
    margin-bottom: 20px;
    left: 5%;
  }
  #nav-user-img {
    display: flex;
    justify-content: center;
    height: 7rem;
    overflow: hidden;
  }
  #img-sec {
    position: relative;
    height: 100%;
    width: 7rem;
    overflow: hidden;
    border-radius: 100%;
    cursor: pointer;
  }
  #img-sec img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  #img-sec span {
    position: absolute;
    color: white;
    bottom: -24px;
    font-size: 0.6rem;
    background-color: orange;
    width: 100%;
    height: 3rem;
    text-align: center;
    border-bottom-left-radius: 80%;
  }
  #nav-content {
    flex: 1;
    background: var(--navbar-dark-primary);
    box-shadow: 0 0 0 16px var(--navbar-dark-primary);
    direction: rtl;
    overflow-x: hidden;
    transition: width 0.2s;
    /* padding-top: 2rem; */
  }

  #nav-content-normal {
    width: calc(100% - 16px);
    height: 50px;
    background-attachment: fixed;
    border-radius: 16px 0 0 16px;
    transition: top 0.2s;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-left: 2rem;
    cursor: pointer;
  }

  #nav-content-highlight {
    position: relative;
    color: var(--nav-text);
    width: calc(100% - 16px);
    height: 50px;
    background: var(--background);
    background-attachment: fixed;
    border-radius: 16px 0 0 16px;
    transition: top 0.2s;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-left: 2rem;
    cursor: pointer;
  }
  #nav-content-highlight .line {
    position: absolute;
    left: -14px;
    height: 50px;
    border-left: 3px solid var(--nav-text);
  }
  #nav-content-highlight span {
    color: var(--nav-text);
  }
  .side-text {
    font-family: Helvetica;
    font-size: medium;
    font-weight: 500;
  }
`;
export default MiniNavBar;
